.measurementsidebar-filtersettings-container{
    position: absolute;
    top: 39px;
    z-index: 1000 !important;

    left: -50px;

    background-color: white;
    border-radius: 3px;
    box-shadow: 0px 0px 5px 0px lightgray;
    border: 1px solid lightgray;

    padding: 4% !important;
    padding-bottom: 10% !important;

    width: 110px;

    font-size: var(--xsmall-fs);
    font-weight: var(--xsmall-fw);
    color: var(--text-color);    
}

.measurementsidebar-filtersettings-header{
    font-weight: var(--large-fw);
    
    padding-left: 2%;
    padding-bottom: 4%;
}

.measurementsidebar-filtersettings-body{
    display: flex;
    flex-direction: column;
    gap: 5px;

    padding-left: 2%;
    padding-right: 2%;
}

.measurementsidebar-filtersettings-item{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    padding-left: 2%;
    padding-right: 4%;
}

.measurementsidebar-filtersettings-item-input{
    /*checkbox input, dont have the check, just fill with color*/
    width: 15px;
    height: 15px;

    border-radius: 3px;
    border: 1px solid lightgray;

    background-color: white;

    cursor: pointer;
}

.measurementsidebar-filtersettings-item-input:checked{
    background-color: #006efa;
}

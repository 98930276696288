.viewoptions-container{
    position: absolute;
    top: 2vh;
    left: 0px;
    z-index: 99999;
}

.viewoptions{
    display: flex;
    flex-direction: column;
    
    border-radius: 3px;
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

    border: 1px solid gainsboro;

    padding-top: 2px;
    padding-bottom: 2px;

    font-size: 0.8em;
}
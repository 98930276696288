.info-icon {
    border: 1px solid var(--revell);
    border-radius: 50%;
    margin-left: 5px;

    background-color: white;
}

.info-icon:hover {
    background-color: var(--alice-blue);
    cursor: help;
}
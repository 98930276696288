.takeofftoolbar-container {
    position: fixed;
    z-index: 2;

    display: flex;
    gap: 5px;
}

.takeofftoolbar-container-left,
.takeofftoolbar-container-left-sidebar {
    top: 25%;
    left: 0.5%;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.takeofftoolbar-container-left-pages-sidebar,
.takeofftoolbar-container-left-pages {
    top: 25%;
    left: 20%;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.takeofftoolbar-container-right,
.takeofftoolbar-container-right-pages {
    top: 25%;
    right: 0.5%;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.takeofftoolbar-container-right-sidebar,
.takeofftoolbar-container-right-pages-sidebar {
    top: 25%;
    right: 18.5%;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.takeofftoolbar-container-top,
.takeofftoolbar-container-top-pages-sidebar {
    top: 5%;
    left: 39%;

    display: flex;
    flex-direction: row;
    align-items: center;
}

.takeofftoolbar-container-top-sidebar {
    top: 5%;
    left: 30%;

    display: flex;
    flex-direction: row;
    align-items: center;
}

.takeofftoolbar-container-top-pages {
    top: 5%;
    left: 48%;

    display: flex;
    flex-direction: row;
    align-items: center;
}

.takeofftoolbar-container-bottom,
.takeofftoolbar-container-bottom-pages-sidebar {
    bottom: 0.5%;
    left: 39%;

    display: flex;
    flex-direction: row;
    align-items: center;
}

.takeofftoolbar-container-bottom-sidebar {
    bottom: 0.5%;
    left: 30%;

    display: flex;
    flex-direction: row;
    align-items: center;
}

.takeofftoolbar-container-bottom-pages {
    bottom: 0.5%;
    left: 48%;

    display: flex;
    flex-direction: row;
    align-items: center;
}

.takeofftoolbar-container-section {
    border-radius: 3px;

    background-color: white;
    box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.3);

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0px;
}

.takeofftoolbar-hover-message-container {
    padding: 0px;
    margin: 0px;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
}

.takeofftoolbar-hover-message-title {
    font-size: 0.8rem;
    font-weight: bold;
}

.takeofftoolbar-hover-message-body {
    font-size: 0.75rem;
}

.takeoff-scalemodal-inputs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 5px;

    padding: 5%;
    padding-bottom: 0px;
}

.takeoff-scalemodal-input {
    padding: 2px;
    padding-left: 5px;
    border-radius: 5px;

    background-color: #fff;
    box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.1);
    border: 1px solid lightgray;
}

.takeoff-scalemodal-input:hover {
    background-color: #fff;
    box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.1);
    border: 1px solid aliceblue;
}

.takeoff-scalemodal-input:focus {
    background-color: #fff;
    box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.1);
    outline: none;
    border: 1px solid #006aef;
}

.takeofftoolbar-button {
    width: 100%;
    min-width: 30px;
    height: 100%;
    min-height: 30px;

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 3px
}

.takeofftoolbar-button:hover {
    cursor: pointer;
    color: #006aef;
    background-color: aliceblue;
}

.takeofftoolbar-button-delete {
    width: 100%;
    min-width: 30px;
    height: 100%;
    min-height: 30px;

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 3px;
}

.takeofftoolbar-button-delete:hover {
    cursor: pointer;
    color: #ff0000;
    background-color: #ffcccc;
}

.takeofftoolbar-annotation-options {
    position: absolute;

    display: flex;
    flex-direction: row;
    gap: 5px;

    left: 35px;

    background-color: white;
    border-radius: 3px;
    box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.3);

    color: black;
}
.takeoffsidebar-container {
    position: fixed;
    top: 40px;
    right: 0px;
    left: auto;

    z-index: 4;

    width: 20%;
    height: 100%;

    background-color: white;
    /*box-shadow: -2px -1px 2px 0 rgba(0, 0, 0, 0.2);*/

    user-select: none;
}

.takeoffsidebar-pages-container {
    position: fixed;
    top: 40px;
    left: 0px;
    right: auto;

    z-index: 4;

    width: 20%;
    height: 100%;

    background-color: white;
    /*box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.1);*/
}

.takeoff-sidebartoggle-left-container {
    user-select: none;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;

    border-bottom: 1px solid #e0e0e0;

    /*box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.1);*/
}

.takeoff-sidebar-left-container {
    position: fixed;
    top: 40px;
    left: 0px;
    right: auto;

    z-index: 4;

    width: 20%;
    height: 100%;

    background-color: white;

    user-select: none;
}

.takeoff-left-toggle {
    position: fixed;

    z-index: 4;

    top: 50px;
    left: 0px;

    display: flex;
    flex-direction: column;
    gap: 5px;
}

.takeoff-left-toggle-item {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 60px;

    background-color: white;

    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;

    padding: 5px;
    padding-left: 10px;
    box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.3);

    height: 31px;

    transition: all 0.3s;
}

.takeoff-left-toggle-item:hover {
    cursor: pointer;

    color: var(--bobyard-blue);
    background-color: aliceblue;

    width: 125px;

    transition: all 0.3s;
}

.takeoff-right-toggle {
    position: fixed;

    top: 50px;
    right: 0px;

    display: flex;
    flex-direction: column;
    align-items: right;
    justify-content: right;
    gap: 5px;

    z-index: 4;

    background-color: red;
}

.takeoff-left-toggle-item-name {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;

    font-size: 14px;
}

.takeoff-right-toggle-item {
    left: auto;
    right: 0px;
    position: absolute;

    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 60px;

    background-color: white;

    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;

    padding: 5px;
    padding-right: 10px;
    box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.3);

    height: 31px;

    z-index: 4;

    transition: all 0.3s;
}

.takeoff-right-toggle-item:hover {
    cursor: pointer;

    color: var(--bobyard-blue);
    background-color: aliceblue;

    width: 125px;
    transition: all 0.3s;
}

.takeoff-right-toggle-item-name {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;

    font-size: 14px;
}

.takeoff-left-toggle-item-name-text {
    display: none;
}

.takeoff-left-toggle-item:hover .takeoff-left-toggle-item-name-text {
    display: block;
}

.takeoff-right-toggle-item-name-text {
    display: none;
}

.takeoff-right-toggle-item:hover .takeoff-right-toggle-item-name-text {
    display: block;
}
.buttonhoverinfo-button {
  position: relative;
  display: inline-block;
}

.buttonhoverinfo-message {
  position: absolute;
  width: fit-content;
  max-width: 200px;
  padding: 10px;
  background-color: #f5f5f5;
  border: 1px solid #006AFF;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  font-size: 14px;
  color: #333;

  font-weight: normal;
  text-decoration: none;

  text-align: left;

  animation: show 750ms linear 0s 1;

  z-index: 999999;

  top: 0px;
}

.buttonhoverinfo-message-small{
  font-size: 0.8em;
  padding: 0.5em;
}

.buttonhoverinfo-message-left {
}

.buttonhoverinfo-message-bottom {
}

.buttonhoverinfo-message-right {
}

.buttonhoverinfo-message-top {
}

@keyframes show {
  0% {
    opacity: 0;
  }

  90% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
.ColorFieldPopup-content {
  width: 0px !important;
  height: 0px !important;
  outline-style: none;
  border-style: none;
  padding: 0px !important;
  z-index: 10000 !important;
}

.ColorFieldPopup-arrow {
  width: 0px !important;
  height: 0px !important;
  outline-style: none;
  border-style: none;
  padding: 0px !important;
}

.sketch-picker {
  z-index: 10000;
}

.takeoffedithistory-pagesidebar-container{
    width: 10vw;
    height: calc(100vh - 40px);
    overflow-x: hidden;
    overflow-y: auto;

    z-index: 1;

    position: absolute;
    top: 40px;
    left: 0;

    background-color: white;
    border-right: 1px solid #e0e0e0;
}

.takeoffedithistory-pagesidebar-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-color: #f5f5f5;

    border: 1px solid #e0e0e0;
    border-radius: 5px;
    padding: 2px;

    position: relative;
}

.takeoffedithistory-pagesidebar-page:hover {
    background-color: #e0e0e0;
    cursor: pointer;
}

.takeoffedithistory-pagesidebar-page-active {
    background-color: aliceblue;
    border-color: var(--bobyard-blue);
}

.takeoffedithistory-pagesidebar-page-active:hover {
    background-color: aliceblue;
}

.takeoffedithistory-pagesidebar-page-measurements{
    position: absolute;
    top: 2px;
    right: 2px;

    font-size: smaller;
    color: var(--bobyard-blue);
    background-color: white;
}
.dashboardproject-container {
    border-radius: 5px;
    background-color: white;
    /*box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.1);*/

    padding: 0px;
}

.dashboardproject-container:hover {
    cursor: pointer;

    box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.1);
}

.dashboardproject-image-container {
    display: flex;
    justify-content: center;
    align-items: center;

    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    width: 100%;
    height: 120px;
    min-height: 120px;
    max-height: 120px;
}

.dashboardproject-image {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    max-width: 240px;
    min-width: 0px;

    min-height: 0px;
    max-height: 120px;

    filter: grayscale(100%);

    object-fit: cover;
}

.dashboardproject-link {
    text-decoration: none;
    color: black;
}

.dashboardproject-link:hover {
    text-decoration: none;
    color: black;
}

.dashboardproject-body {
    border-top: 1px solid var(--revell);

    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 1px;
}

.dashboardproject-menu-container {
    display: flex;
    flex-direction: row;
    justify-content: left;
    gap: 0px;
}

.dashboardproject-menu-item-link {
    text-decoration: none;
    color: black;
}

.dashboardproject-menu-item {
    background-color: #fff;

    display: flex;
    align-items: left;
    justify-content: center;
    gap: 5px;

    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 5px;

    color: var(--bobyard-blue);

    /*font-family: "Maison Neue";*/
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    /* 142.857% */
}

.dashboardproject-menu-item:hover {
    background-color: aliceblue;
    color: #006AFF;
    cursor: pointer;
}

.dashboardproject-menu-item-disabled {
    color: silver;
}

.dashboardproject-menu-item-disabled:hover {
    /*background-color: var(--revell);
    color: white;*/
    background-color: inherit;
    color: silver;
    cursor: not-allowed;
}

.dashboardproject-card-title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    color: var(--Gray-900, #1A1B1E);

    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.32px;

    padding-left: 10px;
    padding-right: 10px;
}

.dashboardproject-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 5px;

    padding-left: 10px;
    padding-right: 10px;
}


.dashboardproject-address {
    padding-left: 10px;
    padding-right: 10px;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: var(--Gray-600, #898990);
    text-align: left;

    /* Text Small */
    /*font-family: "Maison Neue";*/
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    /* 142.857% */
}

.dashboardproject-address-disabled {
    color: silver;
}

.dashboardproject-menu-icon {
    padding: 2px;
    border-radius: 5px;
}

.dashboardproject-menu-icon:hover {
    color: #006AFF;
    background-color: aliceblue;
    cursor: pointer;
}

.dashboard-archived-projects {
    margin-top: 5%;
}
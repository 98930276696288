.estimate-pdfexport-container {
    background-color: #F5F5F5;

    width: 60%;
    margin-left: 20%;
    margin-top: 3%;
    margin-bottom: 20%;

    border-radius: 5px;
    box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1);

    padding-bottom: 3%;
}

.estimate-pdfexport-header {
    display: flex;
    justify-content: right;
    align-items: center;
    padding: 10px;
    padding-bottom: 0px;
}

.estimate-pdfexport-close {
    padding: 5px;
    border-radius: 25%;
}

.estimate-pdfexport-close:hover {
    cursor: pointer;
    background-color: var(--revell);
}

.estimate-pdfexport-uploads {
    padding: 5%;
    padding-top: 3%;

    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 5%;
}

.estimate-pdfexport-upload-body {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.estimate-pdfexport-uploads-title {
    font-size: var(--small-fs);
    font-weight: var(--large-fw);
    color: var(--text-color);
}

.estimate-pdfexport-upload-button {
    padding: 5px 30px;
    border-radius: 5px;
    background-color: var(--bobyard-blue);
    color: white;

    border: 1px solid var(--bobyard-blue);

    font-size: var(--small-fs);
    font-weight: var(--small-fw);

    width: fit-content;
}

.estimate-pdfexport-upload-button:hover {
    cursor: pointer;
    background-color: var(--alice-blue);
    border: 1px solid var(--bobyard-blue);
    color: var(--bobyard-blue);
}

.estimate-pdfexport-upload-input {
    display: none;
}

.estimate-pdfexport-upload-preview {
    display: grid;
    grid-template-columns: 4fr 10fr 1fr 1fr;
    align-items: center;
    gap: 5px;

    height: 50px;
    background-color: white;
    border-radius: 5px;
}

.estimate-pdfexport-upload-preview-image {
    height: 50px;
    object-fit: contain;

    border-radius: 5px;
}

.estimate-pdfexport-upload-preview-name {
    padding-left: 5px;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    font-size: var(--small-fs);
    font-weight: var(--small-fw);
    color: var(--text-color);
}

.estimate-pdfexport-upload-preview-delete {
    display: flex;
    justify-content: right;
    margin-right: 10px;

    color: var(--red);

    padding: 5px;
    border-radius: 4px;
    background-color: transparent;
}

.estimate-pdfexport-upload-preview-delete:hover {
    cursor: pointer;
    color: var(--red);
    background-color: var(--light-red);
}

.estimate-pdfexport-upload-progress {
    width: 80%;
    border-radius: 5px;
    height: 20px;
    border: 1px solid lightgray;
}

.estimate-pdfexport-upload-progress::-webkit-progress-bar {
    background-color: whitesmoke;
}

.estimate-pdfexport-upload-progress::-webkit-progress-value {
    background-color: var(--bobyard-blue);
}

.estimate-pdfexport-upload-item-link {
    text-decoration: none;
    color: var(--text-color);
}

.estimate-pdfexport-upload-item-link:hover {
    text-decoration: none;
}

.estimate-pdfexport-upload-item {
    display: grid;
    grid-template-columns: 20fr 1fr 1fr;
    align-items: center;
    gap: 5px;

    padding: 10px;
    padding-right: 0px;
    border-radius: 5px;
    background-color: white;
    height: 50px;

    padding-left: 20px;
}

.estimate-pdfexport-upload-item-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    font-size: var(--small-fs);
    font-weight: var(--small-fw);
    color: var(--text-color);
}

.estimate-pdfexport-editor-container {
    padding: 5%;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-bottom: 5%;
}

.estimate-pdfexport-editor-title {
    font-size: var(--small-fs);
    font-weight: var(--large-fw);
    color: var(--text-color);

    padding-bottom: 10px;
}

.estimate-pdfexport-editor {
    background-color: white;
}

.estimate-pdfexport-attachments-title {
    font-size: var(--small-fs);
    font-weight: var(--large-fw);
    color: var(--text-color);

    padding-bottom: 10px;
}

.estimate-pdfexport-attachments {
    padding: 5%;
    padding-top: 0px;
}

.estimate-pdfexport-upload-progress-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 5px;

    margin-top: 10px;
    padding: 10px;
    padding-left: 20px;
    border-radius: 5px;
    background-color: white;
    height: 50px;
}

.estimate-pdfexport-upload-progress-name {
    font-size: var(--small-fs);
    font-weight: var(--small-fw);
    color: var(--text-color);

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.estimate-pdfexport-attachment-item {
    margin-top: 1%;
    display: grid;
    grid-template-columns: 2fr 30fr 1fr 1fr;
    align-items: center;
    gap: 5px;

    padding: 10px;
    padding-right: 0px;
    border-radius: 5px;
    background-color: white;
    height: 50px;

    padding-left: 20px;
}

.estimate-pdfexport-attachment-item:hover {
    cursor: pointer;
    background-color: var(--revell);
}

.estimate-pdfexport-attachments-list {
    margin-top: 20px;
}

.estimates-pdf-export-header{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 0px;
}

.estimates-pdf-export-header-title{
    font-size: var(--small-fs);
    font-weight: var(--large-fw);
    color: var(--text-color);
}

.estimates-pdf-export-close-button{
    padding: 5px;
    border-radius: 5px;
}

.estimates-pdf-export-close-button:hover{
    cursor: pointer;
    background-color: var(--revell);
}

.estimate-pdfexport-export-button {
    margin-left: 5%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;

    margin-bottom: 5%;
}

.estimate-pdfexport-upload-item-download {
    display: flex;
    justify-content: right;

    padding: 5px;
    border-radius: 4px;
    background-color: transparent;
}

.estimate-pdfexport-upload-item-download:hover {
    cursor: pointer;
    background-color: var(--alice-blue);
    color: var(--bobyard-blue);
}
.Landing5-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
}

.Landing5-body-title {
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 1rem;
}

.Landing5-body-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    gap: 1rem;
}

.Landing5-body-content-title {
    font-size: 1.5rem;
}
.takeoff-groupplaceholder-container {
    user-select: none;

    display: grid;
    grid-template-columns: 18fr 1fr;
    gap: 4px;
    align-items: center;

    padding-right: 2px;

    font-size: 14px;
    font-weight: 400;

    height: 30px !important;

    color: gray;

    margin-right: 15px;

    /*font-family: 'Maison Neue';*/
}

.takeoff-groupplaceholder-title{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.takeoff-groupplaceholder-link{
    text-decoration: none;
    color: gray;
}

.takeoff-groupplaceholder-link:hover{
    color: #006aef;
    cursor: pointer;
}
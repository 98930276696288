.keybind-container{
    padding-left: 20%;
    padding-right: 20%;
    padding-top: 5%;
    padding-bottom: 10%;
    
    background-color: var(--fill-gray-blue);

    min-height: calc(100vh - 64px);

    color: var(--text-color);
}

.keybind-title{
    font-size: var(--xlarge-fs);
    font-weight: var(--xlarge-fw);
    color: var(--text-color);
}

.keybind-desc{
    font-size: var(--small-fs);
    font-weight: var(--small-fw);
    color: var(--text-color);
}

.keybind-body{
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 20px;
}

.keybind-binds{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.keybind-header{
    display: grid;
    grid-template-columns: 8fr 5fr 5fr 1fr;
    align-items: center;
    gap: 5px;

    font-size: var(--small-fs);
    font-weight: bold;
}

.keybind-section{
    background-color: white;
    border-radius: 5px;
    padding: 2%;

    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
}

.keybind-section-title{
    font-size: var(--large-fs);
    font-weight: var(--large-fw);
}

.keybind-section-content{
    display: flex;
    flex-direction: column;
    gap: 10px;

    padding-top: 5px;
}

.keybind-section-content-item{
    display: grid;
    grid-template-columns: 8fr 5fr 5fr 1fr;
    align-items: center;
    gap: 5px;
}

.keybind-details{
    padding: 2%;

    /*sticky*/
    position: fixed;
    background-color: white;

    width: 25%;

    top: 15%;
    left: 62%;

    z-index: 9999;

    border-radius: 5px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
}

.keybind-section-content-item-title{
    font-size: var(--small-fs);
    font-weight: var(--small-fw);
}

.keybind-details-title{

}

.keybind-input{
    padding-top: 0px;
    padding-bottom: 0px;

    padding-left: 5px;

    width: 80%;

    border-radius: 3px;
    background-color: white;

    border: 1px solid var(--revell);
}

.keybind-input:hover{
    background-color: aliceblue;
    border: 1px solid #006AFF;
    cursor: pointer;
}

.keybind-input:focus{
    outline: none;
    color: #006aef;
    border: 1px solid #006AFF;
    background-color: aliceblue;
}

.keybind-details-title{
    font-size: var(--medium-fs);
    font-weight: var(--medium-fw);
}

.keybind-details-desc{
    font-size: var(--small-fs);
    font-weight: var(--small-fw);
}

.keybind-section-content-item-reset-container{
    display: flex;
    justify-content: center;
}

.keybind-section-content-item-reset{
    padding: 5px 10px;
    border-radius: 5px;
}

.keybind-section-content-item-reset:hover{
    cursor: pointer;
    color: #006aff;
    background-color: aliceblue;
}

.keybind-loading{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin-top: 5%;
}
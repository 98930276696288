.navdropdown-container {
    position: relative;
    z-index: 9999;

    user-select: none;
}

.navdropdown-dropdown {
    position: absolute;
    top: 40px;
    left: 0px;
    z-index: 9999;

    width: 100px;

    flex-direction: column;

    background-color: white;
    border: 1px solid gainsboro;
    border-radius: 4px;

    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
}

.navdropdown-button {
    padding-left: 5px;
    padding-right: 5px;

    height: 40px;

    border-bottom: 1px solid gainsboro;

    display: flex;
    align-items: center;
}

.navdropdown-button:hover {
    cursor: pointer;
    color: #006AFF;
    background-color: aliceblue;
}

.navdropdown-item-link {
    color: black;
    text-decoration: none;
    outline: none;
}

.navdropdown-items {
    display: flex;
    flex-direction: column;
    gap: 0px;
}

.navdropdown-item {
    font-size: 15px;

    width: 100%;

    padding: 5px;
    padding-top: 1%;
    padding-bottom: 1%;

    border-radius: 3px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    font-size: var(--small-fs);
    font-weight: var(--small-fw);
    color: var(--text-color);
    text-decoration: none;

    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    gap: 5px;
}

.navdropdown-item:hover {
    cursor: pointer;
    background-color: var(--revell);
}

.navdropdown-item-active {
    font-size: 15px;

    width: 100%;

    padding: 5px;
    padding-top: 1%;
    padding-bottom: 1%;

    border-radius: 3px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    color: #006AFF;
    background-color: aliceblue;
    text-decoration: none;

    font-size: var(--small-fs);
    font-weight: var(--small-fw);
    text-decoration: none;

    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    gap: 5px;
}
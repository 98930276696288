.measurementsidebar-sortsettings-content-item{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    padding: 1% 3%;
}

.measurementsidebar-sortsettings-content-item:hover{
    background-color: aliceblue;
    cursor: pointer;
}

.measurementsidebar-sortsettings-content-item-active{
    background-color: aliceblue;
    color: #006aef;
}
.logout-wrapper{
    padding: 5%;
    padding-left: 20%;
    padding-right: 20%;
    padding-top: 10%;

    display: flex;
    justify-content: center;
    align-items: center;
}

.logout-title{
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 1%;
}
.contextmenu-container {
    position: absolute;

    transform-origin: top left;

    z-index: 99999;
}

.contextmenu {
    display: flex;
    flex-direction: column;

    border-radius: 3px;
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

    border: 1px solid gainsboro;

    padding-top: 2px;
    padding-bottom: 2px;

    font-size: 0.8em;
}

.contextmenu-item {
    padding-left: 4px;
    padding-right: 4px;
    padding-top: 2px;
    padding-bottom: 2px;

    transition: background-color 0.2s ease-in-out;

    white-space: nowrap;

    display: flex;
    align-items: center;
    justify-content: left;
    gap: 4px;
}

.contextmenu-item:hover {
    background-color: var(--revell);
    cursor: pointer;
}

.contextmenu-item-delete {
    color: red;
}

.contextmenu-item-delete:hover {
    background-color: #ffcccc;
}

.measurementsidebar-measurement-count-icons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0px;
    align-items: center;
}

.measurementsidebar-measurement-dot-toggle {
    height: 30px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
    margin: 0px;
    /*margin-left: -4px;
    padding-right: 4px;*/
    border: 0px;
    background-color: transparent;
}

.measurementsidebar-measurement-dot-toggle-icon:hover {
    background-color: gainsboro;
    cursor: pointer;
    border-radius: 50%;
}
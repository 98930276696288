.takeoffplan-container {
    height: calc(100vh - 40px) !important;
    max-height: calc(100vh - 40px) !important;
    min-height: calc(100vh - 40px) !important;
    overflow: hidden;
}

.takeoffplan-loading-container {
    height: calc(100vh - 40px) !important;
    max-height: calc(100vh - 40px) !important;
    min-height: calc(100vh - 40px) !important;
    overflow: hidden;

    background-color: rgba(115, 147, 179, 0.3);

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: larger;
}

.takeoffplan-drawing-cursor {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    pointer-events: none;
}

.takeoffplan-drawing-cursor-vt {
    position: absolute;
    top: 0;
    bottom: 0;

    border-left: 1px dashed white;
    box-shadow: 0px 0px 3px 0px gray;
}

.takeoffplan-drawing-cursor-hl {
    position: absolute;
    height: 0px;
    left: 0;
    right: 0;

    border-top: 1px dashed white;
    box-shadow: 0px 0px 3px 0px gray;
}
.report-container {
    padding-left: 20%;
    padding-right: 20%;
    padding-top: 5%;
    padding-bottom: 15%;

    min-height: calc(100vh - 64px);

    background-color: var(--fill-gray-blue);
    overflow: hidden;

    color: var(--text-color);
}

.report-title {
    font-size: var(--xlarge-fs);
    font-weight: var(--xlarge-fw);
    color: var(--text-color);
}

.report-graphs {
    display: grid;
    grid-template-columns: 1fr;
}

.report-settings {
    display: grid;
    grid-template-columns: 2fr 2fr 1fr;
    align-items: top;

    padding: 2%;
    padding-top: 1%;
    padding-bottom: 1%;

    margin-top: 2%;
    margin-bottom: 2%;

    border-radius: 5px;
}

.report-settings-header-item {}

.report-settings-title {
    font-weight: var(--large-fw);
    font-size: var(--large-fs);
    color: var(--text-color);

    margin-bottom: 5px;
}

.report-loading {
    font-size: var(--large-fs);
    font-weight: var(--large-fw);
    color: var(--text-color);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    gap: 10px;

    padding-top: 10%;

    width: 100%;
    height: 40%;
}

/*fist immediate child of report-settings-select that is a div*/
.report-settings-select {
    max-width: 200px;
}

.report-settings-select>div:hover {
    cursor: pointer;
    border-color: var(--bobyard-blue);
}

.report-settings-select__control {
    width: 200px;

    outline: none;
    border-radius: 5px;
}

.report-settings-select__control>div:hover {
    cursor: pointer;
}

.report-settings-select__menu {
    border: 1px solid var(--revell);
}

.report-settings-select__menu:hover {
    border: 1px solid var(--revell) !important;
}

.report-settings-select__option:hover {
    cursor: pointer !important;
}

.report-settings-select__option--is-selected {
    background-color: var(--bobyard-blue) !important;
    color: white !important;
}

.report-graph-container {
    background-color: white;
    border-radius: 5px;
    padding: 2%;
    padding-top: 1%;
    padding-bottom: 1%;

    margin-top: 1%;
    margin-bottom: 2%;

    width: 100%;
    user-select: none;

    box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.1);
}

.report-settings-select-container {
    display: flex;
    flex-direction: column;
    align-items: left;

    gap: 5px;
}

.report-graph-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2%;
}

.report-graph-header-title {
    font-size: 1.5em;
    font-weight: 600;

    color: #006aef;
}
.files-container {
    height: calc(100vh - 64px);

    background-color: #F9F9FB;

    display: grid;
    grid-template-columns: 1fr 3fr;

    overflow: hidden;

    user-select: none;

    color: var(--text-color);
}

.files-sidebar {
    overflow: hidden;

    background-color: white;

    border-right: 1px solid var(--revell);
}

.files-sidebar-header {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;

    height: 40px;

    border-bottom: 1px solid var(--revell);
}

.files-sidebar-header-item {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    height: 100%;
}

.files-sidebar-header-item:hover {
    background-color: var(--revell);
}

.files-sidebar-header-item-active {
    background-color: var(--alice-blue);
    color: var(--bobyard-blue);
}

.files-sidebar-content-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    height: 40px;

    width: 100%;

    border-bottom: 1px solid var(--revell);
}

.files-sidebar-content-header-left {
    display: flex;
    align-items: center;
    justify-content: left;
}

.files-sidebar-content-header-item {
    height: 40px;
    width: 40px;

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
}

.files-sidebar-content-header-item:hover {
    background-color: var(--revell);
}

.files-sidebar-content-header-item-active {
    background-color: var(--alice-blue);
    color: var(--bobyard-blue);
}

.files-sidebar-content-header-search {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 39px;

    min-width: none;

    border: 1px solid white;
}

.files-sidebar-content-header-search:hover {
    cursor: text;
    outline: none;
    border: 1px solid #006ffa;
}

.files-sidebar-content-header-search-icon {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 40px;
    width: 30px;

    cursor: pointer;
}

.files-sidebar-content-header-search-input {
    height: 37px;

    padding-left: 5px;

    background-color: white;
    border: 1px solid white;
    box-shadow: none;

    outline: none;

    width: 100%;
}

.files-sidebar-content-header-search-input:focus+.files-sidebar-content-header-search {
    cursor: text;
    outline: none;
    border: 1px solid #006ffa !important;
}

.files-sidebar-content-header-search-clear {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 37px;
    width: 30px;

    cursor: pointer;
}

.files-sidebar-content-header-search-clear:hover {
    background-color: var(--revell);
}

.files-sidebar-sort-container {
    width: 150px;
}

.files-sidebar-sort-item {
    width: 135px;
    padding-left: 5px;

    cursor: pointer;

    height: 40px;

    display: flex;
    align-items: center;
}

.files-sidebar-sort-item:hover {
    background-color: var(--revell);
}

.files-sidebar-sort-item-active {
    background-color: var(--alice-blue);
    color: var(--bobyard-blue);
}

.files-sidebar-sort-item-active:hover {
    background-color: var(--alice-blue);
}

.files-sidebar-content-row {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    height: 40px;

    padding-right: 5px;

    overflow: hidden;
}

.files-sidebar-content-row:hover {
    background-color: var(--revell);
    cursor: pointer;
}

.files-sidebar-content-row-active {
    background-color: var(--alice-blue);
    color: var(--bobyard-blue);
}

.files-row-drag-handle {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 40px;
    width: 40px;

    cursor: pointer;
}

.files-upload-placeholder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 35px;

    height: 100%;
    width: 100%;

    border: 2px dashed lightgray;
    outline: none;

    background-color: #F9F9FB;
}

.files-upload-placeholder:hover {
    cursor: pointer;
    background-color: var(--fill-gray-blue);
    color: var(--bobyard-blue);

    border: 2px dashed var(--bobyard-blue);
}

.files-previews-menu-container {
    position: absolute;
    bottom: 2%;
    left: 40%;

    width: 50%;

    z-index: 100;

    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;

    background-color: white;
    border-radius: 5px;

    box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.75);

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    user-select: none;
}

.files-page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;

    padding-left: 20px;
    padding-right: 20px;

    background-color: white;
    border-bottom: 1px solid var(--revell);

    background-color: whitesmoke;
}

.files-page-header-title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    max-width: 50%;
}

.files-page-name {
    color: var(--text-color);

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-bottom: 2px;

    text-align: center;

    padding-left: 2px;

    background-color: whitesmoke;

    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}

.files-projectfiles-image-container {
    height: calc(100vh - 64px);

    overflow: hidden;

    position: relative;
}

.files-projectfiles-item {
    display: flex;
    justify-content: left;
    align-items: center;

    height: 40px;
    width: 100%;
}

.files-projectfiles-item-icon {
    padding-left: 5px;
}

.files-projectfiles-item:hover {
    background-color: var(--revell);
    cursor: pointer;
}

.files-projectfiles-item-selected {
    background-color: var(--alice-blue);
    color: var(--bobyard-blue);
}

.files-sidebar-tree-body {
    padding-left: 5px;

    height: 100%;
    width: 100%;

    display: flex;
    align-items: center;
}

.files-sidebar-content-row-dragoverlay {
    background-color: white;
    padding-left: 5px;
    border-radius: 2px;

    box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.75);
}

.files-export-placeholder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 35px;

    height: 100%;
    width: 100%;

    outline: none;

    font-size: 20px;
}

.files-rename-modal {
    margin-top: 30%;
}

.files-rename-modal-input {
    width: 100%;

    padding: 5px;

    outline: none;

    border: 1px solid var(--revell);

    border-radius: 3px;
}

.files-rename-modal-input:hover {
    border: 1px solid var(--bobyard-blue);
}

.files-rename-modal-input:focus {
    border: 1px solid var(--bobyard-blue);
}

.files-sidebar-content-body {
    overflow-y: scroll;
    padding-bottom: 50%;
    overflow-x: hidden;
    height: calc(100vh - 64px - 80px);
}


.files-sidebar-content-tree {
    width: 100%;
    height: calc(100vh - 64px - 80px);

    overflow-y: scroll;
    padding-bottom: 50%;
}

.files-previews-shift-menu-container {
    position: absolute;
    bottom: 7%;
    left: 40%;

    width: 50%;

    z-index: 100;

    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;

    background-color: white;
    border-radius: 5px;

    box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.75);

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    user-select: none;
}

.files-sidebar-content-placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 35px;

    height: 40%;
    width: 100%;

    outline: none;
}

.files-export-loading {
    height: calc(100vh - 64px);

    background-color: #F9F9FB;

    display: grid;
    grid-template-columns: 1fr 3fr;

    overflow: hidden;

    user-select: none;

    color: var(--text-color);
}

.projectdetailsnavbar-next {
    display: flex;
    height: 44px;
    padding: 10px 16px;
    justify-content: center;
    align-items: center;
    gap: 4px;

    border-radius: 4px;
    background: var(--Blue-600, #006AFF);
    color: var(--Base-White, #FFF);

    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}

.projectdetailsnavbar-next:hover {
    cursor: pointer;
    background: var(--Blue-700, #0045ad);
}

.projectdetailsnavbar-next-disabled {
    display: flex;
    height: 44px;
    padding: 10px 16px;
    justify-content: center;
    align-items: center;
    gap: 4px;

    border-radius: 4px;

    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;

    background: var(--Gray-200, #E9E9EC);
    color: var(--Gray-400, #B3B3B3);

}

.projectdetailsnavbar-next-disabled:hover {
    cursor: not-allowed;
}
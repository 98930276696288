.projectdashboardnavbar-container {
    position: fixed;
    top: 0;
    z-index: 10;

    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;

    width: 100%;
    height: 64px;

    padding-left: 24px;
    padding-right: 24px;
    background-color: white;
    border-bottom: 1px solid var(--revell);
}

.projectdashboard-container {
    background-color: #FCFCFB;
    background-color: #F4F4F5;

    margin-top: 64px;

    min-height: calc(100vh - 64px);

    display: grid;
    grid-template-columns: 4fr 2fr;
    gap: 4%;

    padding: 5%;
    padding-top: 3%;
}

.projectdashboard-left-container {
    display: flex;
    flex-direction: column;

    gap: 20px;
    overflow: hidden;
}

.projectdashboard-left-section-container {
    padding: 32px;
    background-color: white;
    border: 1px solid var(--revell);

    border-radius: 5px
}

.projectdashboard-right-container {
    background-color: white;
    border: 1px solid var(--revell);
    border-radius: 5px;

    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.projectdashboard-projectdetails-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.projectdashboard-projectdetails-title {
    color: #000;

    /* H6 */
    font-family: "Inter";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 150% */
    letter-spacing: -0.32px;

    display: flex;
    align-items: center;
    gap: 5px;
}

.projectdashboard-projectdetails-address {
    color: var(--Gray-600, #898990);

    /* Text Medium */
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    /* 137.5% */
    letter-spacing: -0.32px;

    padding-bottom: 10px;
}

.projectdashboard-projectdetails-description {
    padding-top: 5px;

    /* Text Medium */
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    /* 137.5% */
    letter-spacing: -0.32px;
}

.projectdashboard-takeofffiles-title {
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 20px;
}

.projectdashboard-takeofffiles-count {
    border-radius: 16px;
    background: #F4F4F5;

    padding: 4px 12px;

    /*font-family: "Maison Neue";*/
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    /* 142.857% */
}

.projectdashboard-projectfiles-header {
    display: flex;
    padding: 32px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-bottom: 1px solid #E9E9EC;
}

.projectdashboard-projectfiles-container {
    display: flex;
    flex-direction: column;

    max-height: 100vh;
    overflow: scroll;
}

.projectdashboard-takeofffiles-error {
    color: var(--Red-500, #FF4D4F);
    padding-top: 10px;

    /* Text Small */
    /*font-family: "Maison Neue";*/
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    /* 142.857% */
}

.projectdashboard-projectdetails-edit {
    display: flex;
    height: 44px;
    padding: 10px 16px;
    justify-content: center;
    align-items: center;
    gap: 4px;

    border-radius: 4px;
    background: var(--Blue-50, #EBF3FF);

    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}

.projectdashboard-projectdetails-edit:hover {
    background: var(--Blue-100, #CCE0FF);
    cursor: pointer;
}

.projectdashboard-step-action {
    display: flex;
    height: 44px;
    padding: 10px 16px;
    justify-content: center;
    align-items: center;
    gap: 4px;

    border-radius: 4px;
    background: var(--Blue-600, #006AFF);
    color: var(--Base-White, #FFF);

    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

.projectdashboard-step-action:hover {
    background: var(--Blue-700, #0045ad);
    cursor: pointer;
}

.projectdetailsnavbar-back {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    height: 44px;
    padding: 10px 16px;
    border-radius: 4px;
    background: #F4F4F5;

    color: var(--Gray-900, #1A1B1E);

    /* Text Medium */
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    /* 137.5% */
    letter-spacing: -0.32px;
}

.projectdetailsnavbar-back:hover {
    background: var(--Gray-300, #D9D9DC);
    cursor: pointer;
}

.projectdashboard-step-actions {
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.projectdashboard-step-action-default {
    display: flex;
    height: 44px;
    padding: 10px 16px;
    justify-content: center;
    align-items: center;
    gap: 4px;

    border-radius: 4px;

    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;

    background: var(--Blue-50, #EBF3FF);
}

.projectdashboard-step-action-default:hover {
    background: var(--Blue-100, #CCE0FF);
    cursor: pointer;
}

.projectdashboard-takeofffiles-container {
    display: flex;
    flex-direction: column;

    padding-top: 20px;
}

.projectdashboard-takeofffiles-file {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;

    padding: 10px 16px;

    border: 1px solid var(--Gray-200, #E9E9EC);
    border-bottom: none;

    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.projectdashboard-takeofffiles-file:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.projectdashboard-takeofffiles-file:last-child {
    border-bottom: 1px solid var(--Gray-200, #E9E9EC);

    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.projectdashboard-takeofffiles-file-date {
    color: var(--Gray-600, #898990);
    font-size: small;

    white-space: nowrap;

    /*font-family: "Maison Neue";*/
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    /* 142.857% */
}

.projectdashboard-takeofffiles-file-name {
    width: 100%;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    /*font-family: "Maison Neue";*/
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    /* 137.5% */
}

.projectdashboard-projectfiles-file {
    padding: 20px 32px;
    border-radius: 4px;

    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;

    border-bottom: 1px solid var(--Gray-200, #E9E9EC);
}

.projectdashboard-projectfiles-file-date {
    color: var(--Gray-600, #898990);
    font-size: small;
}

.projectdashboard-projectfiles-file-ai {
    color: var(--Gray-600, #898990);

    /*font-family: "Maison Neue";*/
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    /* 142.857% */
}

.projectdashboard-projectfiles-file-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.projectdashboard-takeofffiles-measurement-name {
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 10px;

    /*font-family: "Maison Neue";*/
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    /* 137.5% */
}

.projectdashboard-projectfiles-error {
    color: var(--Red-500, #FF4D4F);
    padding: 32px;

    /* Text small */
    /*font-family: "Maison Neue";*/
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    /* 142.857% */
}

.projectdashboard-step-action-disabled {
    display: flex;
    height: 44px;
    padding: 10px 16px;
    justify-content: center;
    align-items: center;
    gap: 4px;

    border-radius: 4px;

    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;

    background: var(--Gray-200, #E9E9EC);
    color: var(--Gray-400, #B3B3B3);
}

.projectdashboard-step-action-disabled:hover {
    cursor: not-allowed;
}

.projectdashboard-projectfiles-file-name {
    width: 100%;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.takeoff-toolguide-auto-find {
    position: absolute;
    bottom: 1vh;
    left: 40vw;
    z-index: 9999;

    text-align: left;

    border-radius: 3px;
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

    border: 1px solid gainsboro;

    padding: 5px;

    user-select: none;

    font-size: 12px;
    line-height: 16px;

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
}

.takeoff-toolguide-selecting-legend {
    position: absolute;
    bottom: 1vh;
    left: 40vw;
    z-index: 9999;

    text-align: left;

    border-radius: 3px;
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

    border: 1px solid gainsboro;

    padding: 5px;

    user-select: none;

    font-size: 12px;
    line-height: 16px;

    display: flex;
    flex-direction: column;
    align-items: left;
    gap: 2px;
}

.takeoff-toolguide-selecting-legend-body{
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    gap: 2px;
}

.takeoff-toolguide-selecting-legend-button{
    color: var(--bobyard-blue);
    padding: 3px 5px;
    border-radius: 3px;
}

.takeoff-toolguide-selecting-legend-button:hover{
    cursor: pointer;
    color: var(--bobyard-blue);
    background-color: var(--alice-blue);
}

.takeoff-toolguide-drawscale {
    position: absolute;
    bottom: 1vh;
    left: 40vw;
    z-index: 9999;

    text-align: left;

    border-radius: 3px;
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

    border: 1px solid gainsboro;

    padding: 5px;

    user-select: none;

    font-size: 12px;
    line-height: 16px;

    display: flex;
    flex-direction: column;
    gap: 5px;
}

.takeoff-toolguide-drawscale-body {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
}

.takeoff-toolguide-drawingpolygon {
    position: absolute;
    bottom: 1vh;
    left: 40vw;
    z-index: 9999;

    text-align: left;

    border-radius: 3px;
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

    border: 1px solid gainsboro;

    padding: 5px;

    user-select: none;

    font-size: 12px;
    line-height: 16px;

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
}

.takeoff-toolguide-auto-find-key {
    padding-left: 5px;
    padding-right: 5px;

    background-color: #006aff;

    display: flex;
    align-items: center;

    border-radius: 3px;

    height: 16px;
}

.takeoff-toolguide-hint-sam {
    position: absolute;
    bottom: 1vh;
    left: 40vw;
    z-index: 9999;

    text-align: left;

    border-radius: 3px;
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

    border: 1px solid gainsboro;

    font-size: 0.9em;

    padding: 5px;

    user-select: none;
}

.takeoff-toolguide-hint-sam-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    gap: 5px;

    font-size: 12px;
}

.takeoff-toolguide-hint-sam-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.takeoff-toolguide-hint-sam-item {
    width: 100%;
    height: 100%;
    background-color: whitesmoke;
    border-radius: 5px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
}

.takeoff-toolguide-hint-sam-item-description {
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 3px;

    font-size: 12px;
    line-height: 16px;

    white-space: nowrap;
}

.takeoff-toolguide-hint-sam-item-title {
    height: 100%;

    color: white;
    background-color: #006aff;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 3px;

    display: flex;
    align-items: center;

    height: 16px;
}

.takeoff-toolguide-hint-sam-title {
    font-size: 1.2em;
    font-weight: bold;
    margin-bottom: 10px;
}

.svg.takeoff-toolguide-rightclick {
    width: 1px;
}

.takeoff-toolguide-hint-sam-item-key {
    padding-left: 5px;
    padding-right: 5px;
}

.takeoff-toolguide-autocount-example {
    position: absolute;
    bottom: 1vh;
    left: 40vw;
    z-index: 9999;

    text-align: left;

    border-radius: 3px;
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

    border: 1px solid gainsboro;

    font-size: 0.9em;

    padding: 5px;

    user-select: none;
}

.takeoff-toolguide-autocount-rect {
    position: absolute;
    bottom: 1vh;
    left: 40vw;
    z-index: 9999;

    text-align: left;

    border-radius: 3px;
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

    border: 1px solid gainsboro;

    font-size: 0.9em;

    padding: 5px;

    user-select: none;
}

.takeoff-toolguide-autocount-poly {
    position: absolute;
    bottom: 1vh;
    left: 40vw;
    z-index: 9999;

    text-align: left;

    border-radius: 3px;
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

    border: 1px solid gainsboro;

    padding: 5px;

    user-select: none;

    font-size: 12px;
    line-height: 16px;

    display: flex;
    flex-direction: column;
    justify-content: left;
    gap: 5px;
}
.ailegend-popup-legends{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    gap: 20px;
}

.ailegend-popup-legend{
    padding: 2%;
    border-radius: 5px;   

    width: 100%;

    border: 1px solid lightgray;
}

.ailegend-popup-legend-name{
    font-size: 1.2em;
}

.ailegend-popup-legend-entries{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    column-gap: 5px;
}

.ailegend-popup-legend-entry{
    display: grid;
    grid-template-columns: 1fr 4fr;
    align-items: center;
    gap: 5px;

    padding: 5px;
    border-radius: 5px;
}

.ailegend-popup-legend-entry:hover{
    background-color: whitesmoke;
    cursor: pointer;
}

.ailegend-popup-legend-entry-image-container{
    display: flex;
    justify-content: center;
    align-items: center;
}

.ailegend-popup-legend-entry-image{
    max-width: 40px;
    max-height: 40px;
}

.ailegend-popup-legend-entry-text{
    display: flex;
    align-items: center;

    font-size: 0.8em;
}
.dashboard-container {
    padding-left: 15%;
    padding-right: 15%;
    padding-top: 5%;
    padding-bottom: 10%;

    height: calc(100vh - 64px);
    overflow: scroll;

    /*background-color: var(--fill-gray-blue);*/
    background-color: #F4F4F5;
}

.dashboard-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dashboard-button {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 10px 16px;
    justify-content: center;
    align-items: center;
    gap: 4px;

    border-radius: 4px;

    color: var(--Base-Black, #151515);

    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;

    border: 1px solid var(--revell);
    background-color: white;
}

.dashboard-button:hover {
    background-color: var(--revell);
}

.dashboard-title {
    color: var(--Gray-900, #1A1B1E);

    /* H5 */
    font-family: "Inter";
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    /* 140% */
    letter-spacing: -0.4px;
}

.dashboard-buttons {
    display: flex;
    gap: 10px;
}

.dashboard-projects-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    margin-top: 2%;
    gap: 16px;
}

.dashboard-projects-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.dashboard-create-project-card {
    background-color: var(--fill-gray-blue);

    border: 2px dashed var(--bobyard-blue);
    border-radius: 5px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;

    font-size: var(--medium-fs);
    font-weight: var(--medium-fw);
    color: var(--bobyard-blue);
}

.dashboard-create-project-card:hover {
    cursor: pointer;
    background-color: var(--alice-blue);
}

.dashboard-navbar-profile-picture {
    width: 40px;
    height: 40px;
    border-radius: 50%;

    object-fit: cover;

    border: 2px solid var(--revell);
}

.dashboard-navbar-profile-picture-initials {
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: var(--medium-fs);
    font-weight: var(--medium-fw);
    color: var(--bobyard-blue);
    text-decoration: none;

    width: 40px;
    height: 40px;
    border-radius: 50%;

    border: 2px solid var(--bobyard-blue);
}

.dashboard-navbar-profile-picture-link {
    text-decoration: none;
    color: black;
}
.profilesidebar-container{
    position: fixed;
    top: 5%;
    left: 0px;

    z-index: 1;

    width: 15%;
    height: 100%;

    padding-top: 5%;
    padding-bottom: 5%;
}

.profilesidebar-item{
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 10px;

    padding: 10px;
    padding-left: 18%;

    transition: background-color 0.2s ease-in-out;

    font-size: var(--medium-fs) !important;
    font-weight: var(--medium-fw) !important;
    color: var(--text-color);
}

.profilesidebar-item:hover{
    background-color: var(--revell);
    cursor: pointer;
}

.profilesidebar-item-active{
    background-color: aliceblue;
    color: #006aff;
}

.profilesidebar-item-link{
    text-decoration: none;
}

.profilesidebar-item-link:hover{
    text-decoration: none;
}
.detailsnavbar-container {
    /*position: fixed;
    top: 40px;*/

    height: 40px;

    z-index: 3;

    background-color: white;
    border-bottom: 1px solid gainsboro;

    user-select: none;

    overflow: hidden;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    position: relative;
}

.detailsnavbar-buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;

    height: 40px;

    z-index: 300;
}

.detailsnavbar-button {
    height: 40px;
    min-width: 30px !important;
    padding-left: 5px;
    padding-right: 5px;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2px;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: clip;

    font-size: var(--small-fs);
    font-weight: var(--small-fw);
    color: var(--text-color);
}

.detailsnavbar-button:hover {
    cursor: pointer;
    background-color: var(--revell);
}

.detailsnavbar-button-disabled:hover {
    cursor: not-allowed;
}

.detailsnavbar-button-active {
    background-color: aliceblue;
    color: #006aff;
}

.detailsnavbar-button-active:hover {
    cursor: pointer;
    background-color: aliceblue;
    color: #006aff;
}

.detailsnavbar-option-color-placeholder {
    /*round circle*/
    width: 18px;
    height: 18px;

    border-radius: 20%;
    border: 1px solid lightgray;

    overflow: hidden;
}

.detailsnavbar-option-color-placeholder:hover {
    cursor: pointer;
}

.detailsnavbar-option-color-placeholder::-webkit-color-swatch {
    border: none;
    border-radius: 50%;
}

.detailsnavbar-row {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    gap: 5px;

    border: 1px solid transparent;

    height: 39px !important;

    padding-left: 5px;
    padding-right: 5px;
}

.detailsnavbar-row:hover {
    border: 1px solid var(--bobyard-blue);

    cursor: text;

    /*background-color: var(--revell);*/
}

.detailsnavbar-row-name {
    font-size: var(--xsmall-fs);
    font-weight: var(--xsmall-fw);
    color: var(--text-color);

    display: flex;
    justify-content: left;
    align-items: center;
    gap: 2px;
}

.detailsnavbar-input {
    outline: none;

    font-size: var(--xsmall-fs);
    font-weight: var(--xsmall-fw);
    color: var(--bobyard-blue);

    height: 28px;

    max-width: 30px;

    background-color: inherit;
}

.detailsnavbar-input:focus {
    background-color: inherit;
}

.detailsnavbar-input-select {
    outline: none;

    font-size: var(--xsmall-fs);
    font-weight: var(--xsmall-fw);
    color: var(--bobyard-blue);

    height: 28px;

    max-width: 100px;

    background-color: inherit;
}

.detailsnavbar-linedetails-height-container {}

.detailsnavbar-row-fraction {
    height: 40px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1px;

    font-size: 8px;
    line-height: 8px;
    font-weight: var(--xsmall-fw);
}

.detailsnavbar-row-fraction-sup {
    display: flex;
    justify-content: center;
    align-items: center;

    border-bottom: 1px solid var(--text-color);
    padding-bottom: 2px;

    width: 10px;
}

.detailsnavbar-divider {
    border-right: 1px solid #EEEEEE;
    width: 1px;
    margin-right: 1px;

    height: 30px;
}

.detailsnavbar-divider-multiselect {
    border-right: 1px solid silver;
}

.detailsnavbar-color-container {
    position: relative;
    z-index: 300 !important;
    display: flex;
    align-items: center;
    justify-content: right;
    text-align: left;
}

.detailsnavbar-groupmenu-dropdown-search-input {
    width: 290px;

    background-color: white;
    font-size: var(--small-fs);
    font-weight: var(--small-fw);
    color: var(--text-color);

    border-radius: 5px;
    border: 1px solid var(--revell);

    padding: 2px 5px;

    outline: none;
}

.detailsnavbar-groupmenu-dropdown-search-input:hover {
    border: 1px solid #006aff;
}

.detailsnavbar-groupmenu-dropdown-search-input:focus {
    background-color: aliceblue;
    border: 1px solid #006aff;
}

.detailsnavbar-groupmenu-dropdown {
    padding: 5px !important;
}

.detailsnavbar-groupmenu-dropdown-groups-container {
    max-height: 60vh;

    margin-top: 2%;

    display: flex;
    flex-direction: column;
    gap: 2px;

    overflow: scroll;
}

.detailsnavbar-groupmenu-dropdown-group-container {
    border-radius: 5px;
    border: 1px solid var(--revell);

    display: flex;
    flex-direction: column;
}

.detailsnavbar-groupmenu-dropdown-group-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    font-size: var(--xsmall-fs);
    font-weight: var(--xsmall-fw);
    color: var(--text-color);

    border-radius: 4px;
    padding: 2px;
}

.detailsnavbar-groupmenu-dropdown-group-header:hover {
    cursor: pointer;
}

.detailsnavbar-groupmenu-dropdown-group-title {
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 2px;
}

.detailsnavbar-groupmenu-dropdown-group-selected {
    border: 1px solid var(--revell);
    border-radius: 3px;
    padding: 0px 5px;

    font-size: var(--xsmall-fs);
    font-weight: var(--xsmall-fw);
    color: var(--bobyard-blue);
}

.detailsnavbar-groupmenu-dropdown-group-select {
    border: 1px solid var(--revell);
    border-radius: 3px;
    padding: 0px 5px;

    font-size: var(--xsmall-fs);
    font-weight: var(--xsmall-fw);
    color: var(--text-color);
}

.detailsnavbar-groupmenu-dropdown-group-select:hover {
    cursor: pointer;
    background-color: var(--revell);
}

.detailsnavbar-groupmenu-dropdown-group-select:focus {
    outline: none;
}

.detailsnavbar-groupmenu-dropdown-group-subgroups {
    margin-top: 2px;
    padding-left: 2px;
    padding-right: 2px;
}

.detailsnavbar-title {
    padding-left: 5px;
    padding-right: 5px;

    font-size: var(--xsmall-fs);
    font-weight: var(--small-fw);
    color: var(--text-color);

    display: flex;
    justify-content: left;
    align-items: center;
    gap: 2px;
}

.detailsnavbar-uom-select {
    z-index: 100 !important;
}

.detailsnavbar-uom-items {
    display: flex;
    flex-direction: column;
    gap: 0px;
}

.detailsnavbar-uom-item {
    font-size: 15px;

    width: 100%;

    padding: 5px;
    padding-top: 1%;
    padding-bottom: 1%;

    border-radius: 3px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    font-size: var(--small-fs);
    font-weight: var(--small-fw);
    color: var(--text-color);
    text-decoration: none;

    display: grid;
    grid-template-columns: 3fr 10fr 1fr;
    align-items: center;
    gap: 5px;
}

.detailsnavbar-uom-item:hover {
    cursor: pointer;
    background-color: var(--revell);
}

.detailsnavbar-uom-item-active {
    font-size: 15px;

    width: 100%;

    padding: 5px;
    padding-top: 1%;
    padding-bottom: 1%;

    border-radius: 3px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    font-size: var(--small-fs);
    font-weight: var(--small-fw);
    color: var(--text-color);
    text-decoration: none;

    color: #006AFF;
    background-color: aliceblue;
    text-decoration: none;

    gap: 5px;
}

.detailsnavbar-groupedit-name-input{
    width: 100%;
    padding: 5px;

    font-size: var(--small-fs);
    font-weight: var(--small-fw);

    border: 1px solid var(--revell);
    border-radius: 3px;

    outline: none;
}

.detailsnavbar-groupedit-name-input:hover {
    border: 1px solid #006aff;
}

.detailsnavbar-groupedit-name-input:focus {
    border: 1px solid #006aff;
    background-color: aliceblue;
}

.detailsnavbar-title{
    font-size: var(--xsmall-fs);
    font-weight: var(--xsmall-fw);
    color: var(--text-color);

    padding-left: 10px;
    padding-right: 10px;
}
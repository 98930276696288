.navbar-aipopup-container {
    position: absolute;
    top: 25px;
    right: 3px;
    z-index: 9999;

    width: 250px;

    text-align: left;

    border-radius: 3px;
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

    border: 1px solid gainsboro;

    font-size: 0.9em;

    padding: 10px;
    padding-bottom: 20px;

    user-select: none;
}

.navbar-aipopup-title{
    font-size: 1.2em;
    margin-bottom: 10px;

    display: flex;
    justify-content: left;
    gap: 5px;
    align-items: center;
}

.navbar-aipopup-body{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.navbar-aipopup-item{
    width: 100%;

    border-radius: 4px;
    padding: 3px 10px;

    white-space: nowrap;
    overflow: hidden;

    display: flex;
    align-items: center;
    justify-content: left;
    gap: 5px;
}

.navbar-aipopup-item:hover{
    background-color: aliceblue;
    cursor: pointer;
}

.navbar-aipopup-group{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5px;

    border-radius: 4px;
    padding: 0px 4px;
}

.navbar-aipopup-group-header{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    padding: 0px 5px;

    /*font-family: 'Maison Neue';*/
}

.navbar-aipopup-group-title{
    font-weight: bold;
}

.navbar-aipopup-group-body{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 4px;
}

.navbar-aipopup-group-item{
    text-align: center;
    background-color: whitesmoke;
    border-radius: 4px;
    padding: 2px 5px;
    
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 1px;
}

.navbar-aipopup-group-item:hover{
    background-color: aliceblue;
    color: #006aff;
    cursor: pointer;
}

.navbar-aipopup-group-item-active{
    text-align: center;
    border-radius: 4px;
    padding: 2px 5px;
    
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 1px;

    background-color: #006aff;
    color: white;
}

.navbar-aipopup-group-item-active:hover{
    color: white;
    cursor: pointer;
}

/*solid blue circle*/
.navbar-aipopup-group-help{
    width: 20px;
    height: 20px;

    background-color: skyblue;
    color: white;
    border-radius: 50%;
    font-size: 0.8em;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
}

.navbar-aipopup-hr{
    height: 14px;
    padding: 0px;
    margin-top: 15px;
    margin-bottom: 0px;
}

.navbar-aipopup-auto-count-button{
    padding: 5px;
    border-radius: 5px;
    background-color: #006aef;
    color: white;

    cursor: pointer;
}
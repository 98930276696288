.groupmenu-container {
  display: inline-block;
  position: relative;
}

.groupmenu-header {
  display: flex;
  align-items: center;
  text-align: center;
  padding-left: 5px;
  height: fill-available;
  overflow: hidden;
}

.groupmenu-header-measure-title {
  /*text break line on overflow*/
  white-space: wrap;
  overflow: hidden;
  text-overflow: clip;

  text-align: left;

  font-size: 0.9em;
}

.groupmenu-header-check {
  height: 20px;
  margin-left: 5px;
  margin-top: -3px;
  vertical-align: middle;
  color: gray;
}

.groupmenu-dropdown-container {
  position: absolute;
  top: 110%;
  left: 0;
  max-height: 65vh;

  width: 18vw;

  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;

  padding: 4%;

  z-index: 1;
  box-shadow: rgba(45, 32, 32, 0.3) 0px 2px 4px 0px;
}

.groupmenu-dropdown-container-small {
  top: auto;
  right: 0px;
  left: auto;
  bottom: 35px;

  padding: 12%;
}

.groupmenu-dropdown-search-input {
  background-color: white;
  border-radius: 3px;
  border: 1px solid rgb(204, 204, 204);

  padding-left: 8px;
  padding-right: 8px;

  background-color: #f6f6fa;
}

.groupmenu-dropdown-search-input:hover {
  background-color: #eef4ff;
}

.groupmenu-dropdown-groups-container {
  max-height: 55vh;

  margin-top: 2%;

  display: flex;
  flex-direction: column;
  gap: 5px;

  overflow: scroll;
}

.groupmenu-dropdown-group-container {
  margin-top: 5px;

  border-radius: 5px;
  border: 1px solid lightgray;
  padding: 1%;
  padding-right: 0px;
}

.groupmenu-dropdown-group-header {
  border-radius: 5px;
  background-color: whitesmoke;

  display: grid;
  grid-template-columns: 1fr 10fr 3fr;
  align-items: center;

  font-size: 0.8rem;
}

.groupmenu-dropdown-group-header:hover {
  cursor: pointer;
}

.groupmenu-dropdown-subgroups-container {
  padding: 2%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.groupmenu-menu-item {
  font-size: small;
  padding: 3px;
  padding-left: 10px;
  border-radius: 3px;
}

.groupmenu-menu-item:hover {
  background-color: #eef4ff;
  cursor: pointer;
}

.groupmenu-menu-item-title-measured {
  color: gray;
}

.groupmenu-dropdown-group-title {
  font-size: 0.9rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 11vw;
}

.groupmenu-dropdown-group-title-container {
  position: relative;
}

.groupmenu-dropdown-group-title-full {
  position: absolute;
  background-color: white;
  width: fit-content;
  top: 23px;
  left: 0px;
  z-index: 9999;

  left: -2%;
  border-radius: 4px;
  padding-left: 2%;
  padding-right: 2%;

  background-color: #f5f5f5;
  border: 1px solid #006aff;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
}

.groupmenu-path {
  font-size: 0.8rem;
  color: gray;
}

.groupmenu-header-button {
  height: 40px;
  padding-left: 5px;
  padding-right: 5px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.groupmenu-header-button:hover {
  cursor: pointer;
  background-color: var(--revell);
}

.takeoffnavbar-container {
    position: fixed;
    width: 100%;

    z-index: 10;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    padding: 0;
    height: 40px !important;
    background-color: #fff;
    border-bottom: 1px solid gainsboro;

    user-select: none;
}

.takeoffnavbar-main-container {
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    height: 40px;
}

.takeoffnavbar-logo-loading {
    height: 30px;
}

.takeoffnavbar-logo {
    width: 20px;
    height: 20px;
}

.takeoffnavbar-measurements-button {
    height: 3vh;

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;

    outline: none;

    border-bottom: 2px solid transparent;
}

.takeoffnavbar-measurements-button:hover {
    cursor: pointer;
    background-color: var(--revell);
}

.takeoffnavbar-toggle-container-left {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;

    height: 40px;

    border-right: 1px solid gainsboro;
    border-bottom: 1px solid gainsboro;
}

.takeoffnavbar-toggle-container-right {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    direction: rtl;
    align-items: center;

    height: 40px;

    border-left: 1px solid gainsboro;
    border-bottom: 1px solid gainsboro;
}

.takeoffnavbar-toggle-button {
    height: 39px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 3px;

    direction: ltr;

    padding-left: 5px;
    padding-right: 5px;

    background-color: white;
}

.takeoffnavbar-toggle-button:hover {
    cursor: pointer;
    background-color: var(--revell);
}

.takeoffnavbar-page-button {
    height: 39px;
    width: 30px;

    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.takeoffnavbar-page-button:hover {
    cursor: pointer;

    color: #006AFF;

    background-color: aliceblue;
}

.takeoffnavbar-center-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;

    width: 100%;
}

.takeoffnavbar-cernter-tags-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.takeoffnavbar-center-tag {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2px;

    font-size: 0.8em;

    padding: 2px 5px;
    border-radius: 5px;
    background-color: #f5f5f5;
}

.takeoffnavbar-page-container {
    height: 40px;
    width: 30%;

    display: grid;
    grid-template-columns: 1fr 20fr 1fr 1fr;
    align-items: center;

    background-color: whitesmoke;

    border-bottom: 1px solid gainsboro;
}

.takeoffnavbar-page-name-container {
    height: 39px;

    position: relative;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;

    z-index: 2;
}

.takeoffnavbar-page-name-container:hover {
    cursor: pointer;
    background-color: aliceblue;
}

.takeoffnavbar-page-name {
    position: relative;
    z-index: 2;

    text-align: center;

    max-width: 20vw;
    min-width: none;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.takeoffnavbar-page-name-input {
    text-align: center;
    width: 100%;
}

.takeoffnavbar-page-name-full {
    position: absolute;
    background-color: white;
    width: fit-content;
    top: 27px;
    left: 0px;
    z-index: 9999;

    font-size: 0.8em;

    left: -2%;
    border-radius: 4px;
    padding-left: 2%;
    padding-right: 2%;

    background-color: #f5f5f5;
    border: 1px solid #006AFF;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
}

.takeoffnavbar-project-name-container {
    height: 40px;

    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
}

.takeoffnavbar-project-name {
    height: 40px;

    color: var(--text-color);

    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    gap: 1px;

    text-decoration: none !important;

    padding-left: 5px;
    padding-right: 5px;
    border-bottom: 1px solid gainsboro;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    max-width: 20vw;
}

.takeoffnavbar-project-name:hover {
    color: #006efa;
    background-color: aliceblue;
    border-bottom: 1px solid gainsboro;
    cursor: pointer;
}

.takeoffnavbar-project-name-icon {
    height: 40px;

    display: flex;
    justify-content: center;
    align-items: center;

    padding: 5px;

    border-bottom: 1px solid gainsboro;
}

.takeoffnavbar-project-name-icon:hover {
    color: #006efa;
    background-color: aliceblue;
    border-bottom: 1px solid gainsboro;
    cursor: pointer;
}

.takeoffnavbar-scale-container {
    position: relative;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    gap: 5px;
}

.takeoffnavbar-scale-text {
    height: 40px;
    padding-left: 5px;
    padding-right: 5px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    gap: 5px;
    border-bottom: 1px solid gainsboro;
}

.takeoffnavbar-scale-text:hover {
    cursor: pointer;
    color: #006AFF;
    background-color: aliceblue;
    border-bottom: 1px solid gainsboro;
}

.takeoffnavbar-scale-menu {
    position: absolute;
    top: 30px;
    left: 25%;
    z-index: 9999;

    text-align: left;

    border-radius: 3px;
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

    border: 1px solid gainsboro;

    padding: 2%;

    font-size: 0.9em;
}

.takeoffnavbar-scale-menu-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    padding: 10px;
    padding-bottom: 5px;
}

.takeoffnavbar-scale-menu-import-container {
    margin-top: 10px;
    border-top: 2px solid gainsboro;
}

.takeoffnavbar-scale-menu-title {
    font-size: var(--small-fs);
    font-weight: var(--large-fw);
    color: var(--text-color);

    font-family: 'Inter', sans-serif !important;
}

.takeoffnavbar-scale-menu-item {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 2px;
    padding-bottom: 2px;

    border-radius: 3px;

    transition: background-color 0.2s ease-in-out;

    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: break-word;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    font-size: small;
}

.takeoffnavbar-scale-menu-item:hover {
    background-color: aliceblue;
    cursor: pointer;
    color: #006AFF;
}

.takeoffnavbar-settings-button-ai {
    height: 40px;

    display: flex;

    flex-direction: row;
    align-items: center;
    gap: 5px;

    outline: none;

    overflow: hidden;
    white-space: nowrap;

    border-bottom: 2px solid transparent;
}

.takeoffnavbar-settings-button-ai:hover {
    cursor: pointer;
    color: #006AFF;
}

.takeoffnavbar-settings-button:hover {
    cursor: pointer;
    color: #006AFF;
}

.takeoffnavbar-settings-container {
    height: 40px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    padding-left: 5px;
    padding-right: 5px;
    border-bottom: 1px solid gainsboro;
}

.takeoffnavbar-settings-container:hover {
    background-color: aliceblue;
    border-bottom: 1px solid gainsboro;
}

.navbar-settings-menu {
    position: absolute;
    top: 25px;
    right: 3px;
    z-index: 9999;

    text-align: left;

    border-radius: 3px;
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

    border: 1px solid gainsboro;

    font-size: 0.9em;
}

.navbar-settings-menu-item-link {
    color: black;
    text-decoration: none;
}

.navbar-settings-menu-item-link:hover {
    text-decoration: none;
}

.navbar-settings-menu-item {
    width: 100%;

    padding: 3px 10px;
}

.navbar-settings-menu-item:hover {
    background-color: whitesmoke;
    cursor: pointer;
}

.takeoffnavbar-logo-container {
    height: 40px;
    width: 30px;

    display: flex;
    justify-content: center;
    align-items: center;

    border-bottom: 1px solid gainsboro;
}

.takeoffnavbar-logo-container:hover {
    cursor: pointer;
    background-color: aliceblue;
}

.takeoffnavbar-logo-tic {
    color: #006AFF;

    display: flex;
    justify-content: center;
    align-items: center;
}

.takeoffnavbar-scale-menu-inputs {
    display: grid;
    grid-template-columns: 4fr 1fr 4fr;
    align-items: center;

    width: 80%;
}

.takeoffnavbar-scale-menu-inputs-left {
    display: grid;
    grid-template-columns: 4fr 1fr 4fr;
    align-items: center;
}

.takeoffnavbar-scale-menu-inputs-middle {
    display: flex;
    justify-content: center;
    align-items: center;
}

.takeoffnavbar-scale-menu-inputs-right {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 5px;
}

.takeoffnavbar-scale-menu-input-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1px;
}

.takeoffnavbar-scale-menu-input {
    background-color: white;

    font-size: var(--small-fs);
    font-weight: var(--small-fw);
    color: var(--text-color);

    padding-left: 2px;
    border-radius: 3px;

    border: 1px solid var(--revell);

    width: 30px;

    text-align: center;
}

.takeoffnavbar-scale-menu-input:hover {
    background-color: white;
    border: 1px solid #006aff;
}

.takeoffnavbar-scale-menu-input:focus {
    background-color: white;
    border: 1px solid #006aff;
    background-color: aliceblue;
}

.takeoffnavbar-scale-menu-container {
    display: flex;
    flex-direction: column;
    gap: 5px;

    width: 300px;

    /*font-family: 'Maison Neue';*/
}

.takeoffnavbar-scale-menu-scales {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;

    padding-left: 10px;
    padding-right: 10px;

    font-size: var(--small-fs);
}

.takeoffnavbar-scale-menu-scales-item-active {
    background-color: aliceblue;
    color: #006AFF;
}

.takeoffnavbar-scale-menu-scales-item {
    background-color: whitesmoke;
    border-radius: 3px;

    text-align: left;

    padding: 2px;
    padding-left: 10px;
    padding-right: 10px;

    width: 100%;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    display: grid;
    grid-template-columns: 3fr 1fr 2fr;
    align-items: center;
}

.takeoffnavbar-scale-menu-scales-item-right {
    background-color: whitesmoke;
    border-radius: 3px;

    text-align: left;

    padding: 2px;
    padding-left: 10px;
    padding-right: 10px;

    width: 100%;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    display: grid;
    grid-template-columns: 1fr 1fr 2fr;
    align-items: center;
}

.takeoffnavbar-scale-menu-scales-item:hover {
    cursor: pointer;
    color: #006AFF;

    background-color: var(--revell);
}

.takeoffnavbar-scale-menu-scales-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    gap: 5px;
}

.takeoffnavbar-scale-menu-scales-right {
    display: flex;
    flex-direction: column;
    align-items: center;

    gap: 5px;
}

.takeoffnavbar-pages-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0px;
}

.takeoffnavbar-pages-item-title {
    text-align: center;

    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.takeoffnavbar-pages-item-thumbnail {
    position: relative;
}

.takeoffnavbar-pages-item-icon-measurements {
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;

    background-color: white;
}

.takeoffnavbar-pages-item-icon-ai {
    position: absolute;
    top: 0;
    right: 0;
    width: 20px;
    height: 20px;

    background-color: white;
}

.takeoffnavbar-pages-container {
    overflow: scroll;

    max-height: 75vh;

    display: block;
}

.takeoffnavbar-projects-container {
    overflow: scroll;

    max-height: 50vh;

    display: block;
}

.takeoffnavbar-buttons-conatiner {
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
}

.takeoffnavbar-next {
    display: flex;
    flex-direction: row;
    gap: 2px;

    color: var(--Blue-600, #006AFF);
    background: var(--Base-White, #FFF);
}

.takeoffnavbar-next:hover {
    cursor: pointer;

    background-color: var(--Blue-600, #006AFF);
    color: var(--Base-White, #FFF);
}

.takeoffnavbar-projectdashboard {
    padding-left: 5px;
    padding-right: 5px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5px;

    height: 39px;
}

.takeoffnavbar-projectdashboard:hover {
    cursor: pointer;
    background-color: aliceblue;
}

.takeoffnavbar-scale-menu-input-container {
    margin-top: 20px;

    border-top: 2px solid gainsboro;
}
.delete-button{
    background-color: white;
    border-radius: 5px;
    border: 1px solid rgb(204, 204, 204);
  
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    transition-duration: 0.3s;

    color: red;
}

.delete-button:hover{
    background-color: red;
    color: white;
    cursor: pointer;
}
  
.measurementdetail-container {
    user-select: none;

    border-top: 1px solid gainsboro;
    background-color: white;

    padding-top: 0px;
    padding-bottom: 0px;

    position: absolute;
    width: 100%;
    bottom: 4%;

    z-index: 10;

    font-size: 0.8em;
}

.measurementdetail-header {
    margin-top: 0px;

    display: flex;
    height: 2.5vh;

    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 2px;
}

.measurementdetail-header-buttons {
    display: flex;
    gap: 2px;
    align-items: center;
}

.measurementdetail-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.measurementdetail-table {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 8px;
    align-items: center;
    gap: 4px;
    padding: 1%;
    padding-top: 0%;
}

.measurementdetail-row {
    display: grid;
    grid-template-columns: 1fr 3fr;
    align-items: center;
    gap: 2px;
    padding-left: 2px;
    padding-right: 2px;
    border-radius: 2px;
}

.measurementdetail-row-name{
}

.measurementdetail-buttons{
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
}

.measurementdetail-row-item {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;
    align-items: center;
}

.measurementdetail-row-value {
    text-align: right;
    white-space: nowrap;
}

.measurementdetail-count-color-container {
    position: relative;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: right;
    text-align: left;
}

.measurementdetail-use-group-color{
    padding: 10px;
    padding-top: 0px;
    padding-bottom: 0px;
    border-radius: 3px;
    background-color: white;
}

.measurementdetail-use-group-color:hover{
    cursor: pointer;
    background-color: aliceblue;
    color: #006aef;
}

.measurementdetail-input {
    width: 80%;
    min-width: 0px;

    text-align: right;
    padding-right: 0px;
    white-space: nowrap;

    border-radius: 2px;
    border: 1px solid whitesmoke;
    margin-right: 1px;

    background-color: whitesmoke;
}

.measurementdetail-input::-webkit-outer-spin-button, .measurementdetail-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.measurementdetail-input:hover {
    cursor: text;

    background-color: aliceblue;
    border: 1px solid #006aef;
}

.measurementdetail-input:focus {
    outline: none;

    color: #006aef;
    background-color: aliceblue;
    border: 1px solid #006aef;
}

.measurementdetail-button{
    padding-left: 4px;
    padding-right: 4px;

    height: 2.5vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.measurementdetail-button:hover {
    cursor: pointer;
    background-color: aliceblue;
}
.database-dnd-sortable-tree_folder_wrapper {
  list-style: none;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;

  height: 40px;
  padding-left: 5px;
}

.database-dnd-sortable-tree_folder_tree-item {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.database-dnd-sortable-tree_folder_clone {
  display: inline-block;
  pointer-events: none;
  padding: 5px;
}

.database-dnd-sortable-tree_folder_clone>.database-dnd-sortable-tree_folder_tree-item {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 24px;
  border-radius: 4px;
}

.database-dnd-sortable-tree_folder_ghost {
  opacity: 0.5;
}

.database-dnd-sortable-tree_folder_disable-selection {
  user-select: none;
  -webkit-user-select: none;
}

.database-dnd-sortable-tree_folder_disable-interaction {
  pointer-events: none;
}

.database-dnd-sortable-tree_folder_line {
  width: 20px !important;
  align-self: stretch;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><line stroke='gray' style='stroke-width: 1px;' x1='50%' y1='0' x2='50%' y2='100%'/></svg>");
}

.database-dnd-sortable-tree_folder_line-last {
  width: 20px !important;
  align-self: stretch;
}

.database-dnd-sortable-tree_folder_line-to_self {
  width: 20px !important;
  align-self: stretch;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><line stroke='gray' style='stroke-width: 1px;' x1='50%' y1='0' x2='50%' y2='100%'/><line stroke='gray' style='stroke-width: 1px;' x1='50%' y1='50%' x2='100%' y2='50%'/></svg>");
}

.database-dnd-sortable-tree_folder_line-to_self-last {
  width: 20px !important;
  align-self: stretch;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><line stroke='gray' style='stroke-width: 1px;' x1='50%' y1='0' x2='50%' y2='50%'/><line stroke='gray' style='stroke-width: 1px;' x1='50%' y1='50%' x2='100%' y2='50%'/></svg>");
}

.database-dnd-sortable-tree_folder_tree-item-collapse_button {
  border: 0;
  width: 20px;
  align-self: stretch;
  transition: transform 250ms ease;
  background: url("data:image/svg+xml;utf8,<svg width='10' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 70 41'><path d='M30.76 39.2402C31.885 40.3638 33.41 40.995 35 40.995C36.59 40.995 38.115 40.3638 39.24 39.2402L68.24 10.2402C69.2998 9.10284 69.8768 7.59846 69.8494 6.04406C69.822 4.48965 69.1923 3.00657 68.093 1.90726C66.9937 0.807959 65.5106 0.178263 63.9562 0.150837C62.4018 0.123411 60.8974 0.700397 59.76 1.76024L35 26.5102L10.24 1.76024C9.10259 0.700397 7.59822 0.123411 6.04381 0.150837C4.4894 0.178263 3.00632 0.807959 1.90702 1.90726C0.807714 3.00657 0.178019 4.48965 0.150593 6.04406C0.123167 7.59846 0.700153 9.10284 1.75999 10.2402L30.76 39.2402Z' /></svg>") no-repeat center;
}

.database-dnd-sortable-tree_folder_tree-item-collapse_button-collapsed {
  transform: rotate(-90deg);
}

.database-dnd-sortable-tree_folder_handle {
  width: 20px !important;
  align-self: stretch;
  cursor: pointer;
  background: url("data:image/svg+xml;utf8,<svg  xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' width='12'><path d='M7 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 2zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 14zm6-8a2 2 0 1 0-.001-4.001A2 2 0 0 0 13 6zm0 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 14z'></path></svg>") no-repeat center;

  display: flex;
  justify-content: center;
  align-items: center;

  height: 40px;
}

.database-dnd-sortable-tree_folder_handle:hover {
  background-color: aliceblue;
  color: var(--bobyard-blue);
  cursor: pointer;
}
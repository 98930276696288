.default-button{
    background-color: white;
    border-radius: 5px;
    border: 1px solid var(--revell);
    user-select: none;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    transition-duration: 0.3s;
    cursor: pointer;
}
  
.default-button:hover{
    border-color: var(--text-color);
    background-color: var(--revell);
    cursor: pointer;
}
  
.default-button-small{
    background-color: white;
    border-radius: 4px;
    border: 1px solid rgb(204, 204, 204);
  
    padding: 1px;
    padding-left: 5px;
    padding-right: 5px;
    transition-duration: 0.3s;

    font-size: 0.8rem;
}

.default-button-disabled{
    color: white;
    background-color: var(--revell);
    border: none;
}

.default-button-disabled:hover{
    color: white;
    background-color: var(--revell);
    border: none;
    cursor: not-allowed;
}

.default-button-active{
    background-color: #006aef;
    color: white;
}

.default-button-active:hover{
    background-color: #006aef;
    color: white;
    cursor: default !important;
}
.landing2-container {
    /*font family dm sans*/
    font-family: 'DM Sans', sans-serif !important;

    overflow: hidden;
}

.landing2-header-buttons{
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.landing2-navbar {
    padding-top: 2%;
    padding-bottom: 2%;
    background-color: #043873;
    color: white;

    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;

    padding-left: 10%;
    padding-right: 10%;
}

.landing2-navbar-logo-img{
    height: 70px;
}

.landing2-navbar-items {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.landing2-navbar-item{
    color: white;
    text-decoration: none;
}

.landing2-navbar-item:hover{
    cursor: pointer;
    color: #FFE492;
}

.landing2-header-container {
    position: relative; 
}

.landing2-header {
    position: absolute;

    top: 0px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    gap: 10%;

    padding-left: 10%;
    padding-right: 10%;

    padding-top: 5%;
    padding-bottom: 5%;

    z-index: 2;
}

.landing2-header-background {
    background-color: #043873;

    transform: rotate(0deg);

    width: 100%;

    padding-top: 5%;
    padding-bottom: 5%;

    overflow: hidden;

    /*send to back so that it is not drawn over*/
    z-index: 1;
}

.landing2-header-background-image{
    width: 120%;
    margin-left: -10%;
}

.landing2-header-video {
    width: 500px;
    height: 281.25px;

    border-radius: 5px;
}

.landing2-header-title {
    font-size: 64px;
    font-weight: 800;
    line-height: 64px;
    color: white;
}

.landing2-header-subtitle {
    margin-top: 2%;
    margin-bottom: 7%;

    font-size: 18px;
    color: white;
}

.landing2-header-note{
    margin-top: 2%;
    font-size: 14px;
    color: white;
}

.landing2-startbutton {
    color: white;

    background-color: #4F9CF9;
    padding: 20px;
    padding-top: 10px;
    padding-bottom: 10px;

    border-radius: 8px;
    outline: none;
    border: none;
}

.landing2-startbutton:hover {
    cursor: pointer;
    background-color: #006AFF;

    transition: background-color 0.5s;
}

.landing2-navbar-login{
    background-color: #FFE492;
    padding: 20px;
    padding-top: 10px;
    padding-bottom: 10px;

    border-radius: 8px;
    outline: none;
    border: none;
}

.landing2-navbar-login:hover {
    cursor: pointer;
    background-color: #FFD700;

    transition: background-color 0.5s;
}

.landing2-feature1-container{
    position: relative;

    margin-top: -10%;

    display: block;

    height: 110vh;

    margin-bottom: 5%;
}

.landing2-feature-container{
    position: relative;

    display: block;

    min-height: 50vh;

    padding-bottom: 10%;
}

.landing2-feature2-background{
    position: relative;

    transform: rotate(-20deg);

    z-index: 1;
}

.landing2-feature2-background-image{
    position: absolute;

    width: 40%;
    margin-left: 20%;
    margin-top: -20%;

    z-index: 1;
}

.landing2-feature{
    width: 100vw;

    position: absolute;

    top: 0px;
    left: 0px;

    padding-left: 10%;
    padding-right: 10%;

    padding-top: 0%;
    padding-bottom: 10%;

    gap: 10%;

    z-index: 2;
}

.landing2-feature-background{
    transform: rotate(0deg);

    width: 100%;
    overflow: hidden;

    margin-top: 20%;

    z-index: 1;
}

.landing2-feature-background-image{
    width: 40%;
    margin-left: 70%;
}

.landing2-feature-title-container{
    margin-top: 45px;

    position: relative;
}

.landing2-feature-title{
    font-size: 64px;
    font-weight: 800;
    line-height: 64px;

    /*font: inter*/
    font-family: 'Inter', sans-serif !important;

    z-index: 2;
}

.landing2-feature-pdf-container{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.landing2-feature-pdf{
    margin-top: 4%;
    height: 65vh;

    width: 80%;
    margin-left: 10vw;
    margin-right: 10vw;

    border-radius: 5px;
    box-shadow: 5px 5px 5px gray;
}

.landing2-feature-subtitle{
    font-size: 18px;
    width: 60%;

    margin-top: 1%;
}

.landing2-feature-title-slash{
    width: 20%;

    margin-left: 35%;

    z-index: 1;
}

.landing2-feature2{
    display: flex;
    flex-direction: row;
    align-items: center;

    padding-left: 10%;
    padding-right: 10%;

    position: relative;
    z-index: 2;
}

.landing2-feature-img{
    height: 300px;
    border: 2px solid gray;
    border-radius: 5px;
    box-shadow: 5px 5px 5px gray;
}

.landing2-feature2-title-container{
    position: relative;
}

.landing2-feature2-title{
    font-size: 64px;
    font-weight: 800;
    line-height: 64px;

    /*font: inter*/
    font-family: 'Inter', sans-serif !important;

    z-index: 2;
}

.landing2-feature2-title-slash{
    width: 50%;
}

.landing2-feature-button{
    margin-top: 5%;
}

.landing2-feature3-img{
    height: 400px;
    border: 2px solid gray;
    border-radius: 5px;
    box-shadow: 5px 5px 5px gray;

    z-index: 2;

    position: relative;
}

.landing2-feature3{
    position: absolute;

    top: 0px;

    display: grid;
    grid-template-columns: 3fr 2fr;
    align-items: center;

    padding-left: 10%;
    padding-right: 10%;

    z-index: 2;
}

.landing2-feature3-title-container{
    position: relative;

    margin-top: 45px;
}

.landing2-feature3-title{
    position: absolute;

    font-size: 64px;
    font-weight: 800;
    line-height: 64px;

    top: -45px;

    /*font: inter*/
    font-family: 'Inter', sans-serif !important;

    z-index: 2;
}

.landing2-feature3-title-slash{
    width: 45%;

    z-index: 1;
}

.landing2-feature3-background{
    position: relative;

    transform: rotate(0deg);

    z-index: 1;
}

.landing2-feature3-background-image{
    position: absolute;
    width: 100%;

    margin-left: 51%;
    
    margin-top: -15%;

    z-index: 1;

    /*flip image*/
    transform: scaleX(-1);
}

.landing2-feature3-subtitle{
    font-size: 18px;
    width: 80%;

    margin-top: 1%;
}

.landing2-feature4-container{
    background-color: #043873;

    position: relative;

    display: block;

    margin-top: 10%;
    margin-bottom: 5%;

    padding-top: 5%;
    padding-bottom: 5%;
}

.landing2-feature4-title{
    color: white;

    position: absolute;

    font-size: 64px;
    font-weight: 800;
    line-height: 64px;

    top: -45px;

    /*font: inter*/
    font-family: 'Inter', sans-serif !important;

    z-index: 2;
}

.landing2-feature4-subtitle{
    color: white;

    font-size: 18px;
    width: 80%;

    margin-top: 1%;

    position: relative;
}

.landing2-feature4-title-container{
    position: relative;

    margin-top: 45px;
}

.landing2-feature4-title-slash{
    width: 40%;

    z-index: 1;

    position: relative;
}

.landing2-feature4-img{
    height: 300px;
    border: 2px solid gray;
    border-radius: 5px;
}

.landing2-feature4-background{
    position: relative;

    transform: rotate(-30deg);

    z-index: 1;
}

.landing2-feature4-background-image{
    position: absolute;
    width: 60%;

    margin-top: -50%;
    margin-left: -30%;

    z-index: 1;

    /*flip image*/
    transform: scaleX(-1);
}

.landing2-feature5-title-container{
    position: relative;

    margin-top: 110px;
}

.landing2-feature5-title{
    font-size: 64px;
    font-weight: 800;
    line-height: 64px;

    /*font: inter*/
    font-family: 'Inter', sans-serif !important;

    z-index: 2;

    color: white;
}

.landing2-feature5-title-slash{
    width: 40%;

    z-index: 1;

    position: relative;
}

.landing2-feature5-container{
    position: relative;

    display: block;

    margin-top: 10%;
    margin-bottom: 10%;

    padding-top: 5%;
    padding-bottom: 10%;
}

.landing2-feature5-background{
    transform: rotate(0deg);

    z-index: 1;
}

.landing2-feature5-background-image{
    position: absolute;
    width: 100%;

    margin-left: 30%;
    
    margin-top: -15%;

    z-index: 1;

    /*flip image*/
    transform: scaleX(-1);
}

.landing2-feature5{
    position: absolute;

    top: 0px;

    display: grid;
    grid-template-columns: 3fr 2fr;
    align-items: center;

    padding-left: 10%;
    padding-right: 10%;

    z-index: 2;
}

.landing2-plans-container{
    position: relative;

    display: block;

    margin-top: 10%;
    margin-bottom: 5%;

    padding-top: 10%;
    padding-bottom: 5%;

    padding-left: 10%;
    padding-right: 10%;

    z-index: 1;
}

.landing2-plans-title-container{
    position: relative;

    margin-top: 45px;
}

.landing2-plans-title{
    position: absolute;
    width: 100%;

    font-size: 64px;
    font-weight: 800;
    line-height: 64px;

    top: -45px;

    text-align: center;

    /*font: inter*/
    font-family: 'Inter', sans-serif !important;

    z-index: 2;
}

.landing2-plans-title-slash{
    position: absolute;

    width: 17%;

    margin-left: 53%;

    z-index: 1;
}

.landing2-plans{
    margin-top: 10%;

    display: grid;
    grid-template-columns: 3fr 4fr 3fr;
    gap: 2%;
}

.landing2-plan-free-container{
    background-color: white;
    border: 2px solid #FFE492;
    border-radius: 10px;

    margin-top: 5%;
    margin-bottom: 5%;

    padding-top: 20%;
    padding-bottom: 20%;

    padding-left: 10%;
    padding-right: 10%;

    position: relative;
    z-index: 3;
}

.landing2-plan-enterprise-container{
    background-color: white;
    border: 2px solid #FFE492;
    border-radius: 10px;

    margin-top: 5%;
    margin-bottom: 5%;

    padding-top: 20%;
    padding-bottom: 20%;

    padding-left: 10%;
    padding-right: 10%;
}

.landing2-plan-basic-container{
    background-color: #043873;
    border-radius: 5px;

    border-radius: 10px;

    padding-top: 15%;
    padding-bottom: 15%;

    padding-left: 10%;
    padding-right: 10%;

    color: white;
}

.landing2-plan-feature{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5%;

    font-size: 20px;
    line-height: 20px;
}

.landing2-plan-feature-icon{
    color: #FFE492;
}

.landing2-plan-feature-container{
    display: flex;
    flex-direction: column;
    gap: 20px;

    margin-bottom: 10%;
}

.landing2-plan-title{
    font-size: 30px;
    font-weight: 600;

    margin-bottom: 5%;

    /*font: inter*/
    font-family: 'Inter', sans-serif !important;
}

.landing2-plan-price-container{
    margin-bottom: 10%;
}

.landing2-plan-price{
    font-size: 40px;
    font-weight: 700;

    color: #006AFF;
}

.landing2-plan-starter-price{
    font-size: 40px;
    font-weight: 700;

    color: white;
}

.landing2-plan-price-subtitle{
    font-size: 20px;

    margin-bottom: 5%;

    margin-left: 3%;
}

.landing2-plan-starter-price-container{
    margin-bottom: 10%;

    border-radius: 5px;

    border: 1px solid #006AFF;

    padding: 5%;

    align-items: center;
    text-align: center;

    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.landing2-plan-prices-container{
    width: 110%;
    margin-left: -5%;

    margin-top: 5%;
    margin-bottom: 5%;

    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 4%;
}

.landing2-plan-starter-price-title{
    text-align: center;
}

.landing2-plan-starter-price-savings{
    text-align: center;
}

.landing2-testimonials-title{
    width: 100%;

    font-size: 64px;
    font-weight: 800;
    line-height: 64px;

    text-align: center;

    /*font: inter*/
    font-family: 'Inter', sans-serif !important;

    z-index: 2;
}

.landing2-testimonials-title-container{
    position: relative;

    text-align: center;

    z-index: 2;

    margin-top: 45px;
}

.landing2-testimonials-title-slash{
    width: 10%;

    margin-left: 67%;

    z-index: 1;
}

.landing2-testimonials-carousel{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 4%;

    padding-left: 10%;
    padding-right: 10%;

    margin-top: 2%;
}

.landing2-testimonials-container{
    position: relative;
    z-index: 2;

    margin-top: 6%;
}

.landing2-testimonial-container{
    position: relative;
    z-index: 2;

    border-radius: 5px;

    padding-top: 15%;
    padding-bottom: 10%;
    padding-left: 10%;
    padding-right: 10%;

    background-color: #4F9CF9;
}

.landing2-current-testimonial-container{
    box-shadow: 15px 10px 50px rgba(0, 0, 0, 0.1);

    border-radius: 5px;

    padding-top: 15%;
    padding-bottom: 10%;
    padding-left: 10%;
    padding-right: 10%;

    background-color: white;
}

.landing2-testimonial-quote{
    width: 30%;
    margin-bottom: 10%;
}

.landing2-testimonial-text{
    font-size: 18px;
    line-height: 26px;
}

.landing2-testimonial-other-text{
    font-size: 18px;
    line-height: 26px;

    color: white;
}

.landing2-testimonial-auth{
    font-size: 24px;
    font-weight: 600;

    text-align: right;
}

.landing2-testimonial-occ{
    font-size: 18px;

    text-align: right;
}

.landing2-testimonial-other-occ{
    font-size: 18px;

    text-align: right;

    color: white;
}

.landing2-testimonial-body{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.landing2-testimonial-buttons{
    width: 10%;

    margin-left: 45%;

    margin-top: 2%;

    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.landing2-testimonial-button{
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;

    padding-top: 5px;
    padding-bottom: 5px;

    padding-left: 10px;
    padding-right: 10px;

    border-radius: 20px;

    background-color: #FFE492;

    border: none;
    outline: none;
}

.landing2-footer-container{
    position: relative;

    background-color: #043873;

    margin-top: 10%;

    padding-top: 2%;
    padding-bottom: 2%;
    padding-left: 15%;
    padding-right: 15%;
}

.landing2-footer-background-container{
    width: 100%;
}

.landing2-footer-background-image{
    width: 200%;
    margin-left: -30%;
}

.landing2-footer-body{
    position: absolute;

    top: 25%;

    width: 70%;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    color: white;
}

.landing2-footer-title{
    font-size: 28px;
}

.landing2-footer-subtitle{
    font-size: 18px;
}

.landing2-footer-get-started{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.landing2-footer-bottom{
    padding-top: 1%;

    border-top: 2px solid #2E4E73;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    color: white;
}

.landing2-footer-social{
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.landing2-footer-social-icon{
    height: 24px;
    font-size: 20px;
    color: white;
}

.landing2-footer{
    margin-top: 2%;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10%;
}

.landing2-footer-section-title{
    font-size: 18px;
    font-weight: 600;
}

.landing2-footer-section{
    display: flex;
    flex-direction: column;
    gap: 10px;

    margin-top: 5px;
}

.landing2-navbar-container-mobile{
    display: none;
}

.landing2-navbar-icon-mobile{
    display: none;
}

.landing2-header-background2-image-mobile{
    display: none;
}

.landing2-feater1-button-mobile{
    display: none;
}

.landing2-current-testimonial-container-mobile{
    display: none;
}

.landing2-feature-image-container{
    display: none;
}

.landing2-footer-body-mobile{
    display: none;
}

.landing2-footer-background2-image{
    display: none;
}

.landing2-feature3-image-container-mobile{
    display: none;
}

.landing2-feature5-image-container-mobile{
    display: none;
}

.landing2-backedby-title{
    font-size: 20px;
    text-align: center;

    /*font: inter*/
    font-family: 'Inter', sans-serif !important;
}

.landing2-backedby-container{
    margin-top: 10%;

    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    background-color: inherit;
}

.landing2-backedby-logos{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 40px;

    margin-top: 0.5%;
}

.landing2-backedby-img{
    height: 100px;
}
.profile-container{
    padding-left: 20%;
    padding-right: 20%;
    padding-top: 5%;
    padding-bottom: 20%;
    
    height: calc(100vh - 64px);

    overflow: hidden;

    background-color: #F9F9FB;

    color: var(--text-color);
}

.profile-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.profile-title{
    font-size: var(--xlarge-fs);
    font-weight: var(--xlarge-fw);
}

.profile-details-container{
    display: flex;
    flex-direction: column;
    margin-top: 5%;
    gap: 50px;
}

.profile-details-item{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: left;
    gap: 5px;

    width: 80%;
}

.profile-details-row{
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 20px;
}

.profile-details-title{
    font-size: 20px;
    font-weight: bold;
}

.logout-link{
    color: black;
    text-decoration: none;

    max-width: 80px;
}

.profile-details-input{
    width: 100%;
    height: 40px !important;
    padding: 5px;
    padding-left: 10px !important;
    border-radius: 5px;
    border: 1px solid var(--revell);

    font-size: var(--small-fs);
    font-weight: var(--small-fw);
    color: var(--text-color);

    outline: none;
}

.profile-details-input:hover{
    border: 1px solid var(--bobyard-blue);
}

.profile-details-input:focus{
    background-color: var(--alice-blue);
    border: 1px solid var(--bobyard-blue);
}

.profile-picture-image{
    width: 100px;
    height: 100px;
    border-radius: 50%;

    object-fit: cover;

    border: 3px solid var(--bobyard-blue);   
}

.profile-picture-initials{
    width: 100px;
    height: 100px;
    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 42px;
    font-weight: 400;

    background-color: white;
    color: var(--bobyard-blue);
    border: 3px solid var(--bobyard-blue);
}

.profile-picture-container{
    margin-top: 2%;
    position: relative;

    width: fit-content;
}

.profile-picture-container:hover{
    cursor: pointer;
}

.profile-picture-edit-symbol{
    position: absolute;
    bottom: 0px;
    left: 70px;

    width: 35px;
    height: 35px;
    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: white;
    color: var(--bobyard-blue);
    border: 3px solid var(--bobyard-blue);
}
.pagessidebar-container {
    display: block;
    user-select: none;
}

.pagessidebar-header-container {
    z-index: 5;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding-left: 10px;

    border-bottom: 1px solid gainsboro;
}

.pagessidebar-header {
    height: 39px;

    z-index: 6;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.pagessidebar-header-buttons {
    z-index: 7;

    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
}

.pagessidebar-title {
    font-weight: bold;
}

.pagessidebar-body-list {
    padding: 2%;
    margin-top: 2%;

    display: grid;
    grid-template-columns: 1fr;
    gap: 5px;

    max-height: calc(100vh - 80px);
    padding-bottom: 20vh;

    overflow-y: scroll;
    overflow-x: hidden;
}

.pagessidebar-body {
    max-height: calc(100vh - 80px);
    padding-bottom: 5vh;

    overflow-y: scroll;
    overflow-x: hidden;
}

.pagessidebar-body-grid {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(auto-fit, minmax(90px, 1fr));
    gap: 5px;
    padding: 10px;
}

.pagessidebar-grid-body {
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 90vh;
    padding-bottom: 20vh;
}

.pagessidebar-grid-body::-webkit-scrollbar,
.pagessidebar-body-list::-webkit-scrollbar,
.pagessidebar-body::-webkit-scrollbar {}

.pagessidebar-grid-body::-webkit-scrollbar-thumb,
.pagessidebar-body-list::-webkit-scrollbar-thumb,
.pagessidebar-body::-webkit-scrollbar-thumb {
    background: Gray;
    border-radius: 3px;
    border-left: 4px solid transparent;
    background-clip: padding-box;
}

.pagessidebar-grid-body::-webkit-scrollbar-track,
.pagessidebar-body-list::-webkit-scrollbar-track,
.pagessidebar-body::-webkit-scrollbar-track {
    background: transparent;
}

.pagessidebar-grid-body::-webkit-scrollbar-thumb:hover,
.pagessidebar-body-list::-webkit-scrollbar-thumb:hover,
.pagessidebar-body::-webkit-scrollbar-thumb:hover {
    background: DimGray;
    width: 10px;
    border: 0;
    cursor: pointer;
}

.pagessidebar-page {
    position: relative;

    padding: 2%;
    border-radius: 5px;
    border: 1px solid lightgray;

    min-width: 0px;
    width: 100%;

    height: 100%;
    min-height: 0px;

    overflow: hidden;
}

.pagessidebar-page:hover {
    cursor: pointer;
    background-color: var(--revell);
}

.pagessidebar-page-dragging {
    top: auto !important;
    left: auto !important;

    background-color: whitesmoke;
    opacity: 0.7;

    padding-left: 5px;

    overflow: hidden;

    cursor: grabbing;
    width: 200px !important;
    height: fit-content !important;

    border: 1px solid #006aef;
    border-radius: 5px;
}

.pagessidebar-page-measurement-count {
    position: absolute;
    top: 0px;
    left: 0px;

    z-index: 10;

    font-size: 0.8rem;

    color: #006aef;

    /*border: 1px solid black;*/
    border-radius: 4px;

    padding: 3px;
    padding-top: 0px;
    padding-bottom: 0px;

    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pagessidebar-page-measurement-ai {
    position: absolute;
    top: 1px;
    right: 0px;

    z-index: 10;

    font-size: 0.8rem;

    color: #006aef;

    /*border: 1px solid black;*/
    border-radius: 4px;

    padding: 3px;
    padding-top: 0px;
    padding-bottom: 0px;

    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pagessidebar-page-name-container {
    position: absolute;
    width: 100%;

    z-index: 11;

    bottom: 0px;
    left: 3px;
}

.pagessidebar-page-name-input {
    position: absolute;
    bottom: 0px;
    left: 5px;

    max-width: 90%;

    white-space: wrap;
    overflow: hidden;
    overflow-wrap: break-word;

    z-index: 99;

    font-size: 0.8rem;
}

.pagessidebar-page-name-input:hover {
    cursor: text;
    color: #006aef;
}

.pagessidebar-page-name-input:focus {
    outline: none;
    color: #006aef;
}

.pagessidebar-page-name {
    bottom: 0px;
    left: 5px;

    max-width: 90%;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    z-index: 99;

    font-size: 0.8rem;
}

.pagessidebar-page-name-full {
    position: absolute;
    background-color: white;
    top: 20px;
    left: auto;
    right: 0px;
    z-index: 9999;

    font-size: 0.8em;

    width: 150px;
    max-width: 200px;

    left: -2%;
    border-radius: 4px;
    padding-left: 2%;
    padding-right: 2%;

    background-color: #f5f5f5;
    border: 1px solid #006AFF;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
}

.pagessidebar-page-image-list {
    width: 100%;
    min-width: none;
    min-height: none;

    opacity: 0.5;
}

.pagessidebar-page-image-grid {
    min-height: 60px;
    height: fill-available;

    opacity: 0.4;
}

.pagessidebar-page-active {
    color: #006aef;

    border: 1px solid #006aef;
}

.pagessidebar-page-star {
    position: absolute;
    top: 1px;
    right: 1px;

    z-index: 10;

    color: #006aef;
}

.pagessidebar-page-options-button {
    position: absolute;
    top: 1px;
    right: 18px;

    z-index: 10;

    color: #006aef;
}

.pagessidebar-page-options-button:hover {
    cursor: pointer;

    background-color: white;
    border-radius: 50%;
}

.pagessidebar-page-star:hover {
    cursor: pointer;

    background-color: white;
    border-radius: 50%;
}

.pagessidebar-body-names-search {
    margin-top: 2%;
    margin-left: 2%;
}

.pagessidebar-body-names-search-input {
    padding-left: 4px;

    border-radius: 3px;
    border: 1px solid lightgray;
    background-color: whitesmoke;
}

.pagessidebar-body-names-pages {
    border-left: 1px solid gainsboro;
    margin-left: 2%;
}

.pagessidebar-body-names-page {
    border: 1px solid white;
    font-size: 0.9em;

    border-radius: 0px;
    padding-left: 5px;

    display: grid;
    grid-template-columns: 30fr 5fr 2fr 3fr 3fr;
    padding-right: 10px;
    gap: 1px;
    align-items: center;

    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    font-size: var(--small-fs);
    font-weight: var(--small-fw);

    position: relative;
}

.pagessidebar-body-names-page:hover {
    cursor: pointer;
    border: 1px solid var(--revell);
    background-color: var(--revell);
}

.pagessidebar-body-names-page-active {
    border: 1px solid var(--bobyard-blue);
    color: var(--bobyard-blue);
}

.pagessidebar-body-names-page-active:hover {
    cursor: pointer;
    border: 1px solid var(--bobyard-blue);
    color: var(--bobyard-blue);
}

.pagessidebar-body-names-page-star {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 19px;
    height: 19px;

    padding: 0px;
}

.pagessidebar-body-names-page-star:hover {
    cursor: pointer;

    background-color: gainsboro;
    border-radius: 20%;
}

.pagessidebar-body-names-page-star-filled {
    color: #006aef;
}

.pagessidebar-body-names-page-title-container {
    position: relative;
}

.pagessidebar-body-names-page-title-full {
    position: absolute;
    background-color: white;
    width: fit-content;
    top: 22px;
    left: 0px;
    z-index: 9999;

    font-size: 0.8em;

    left: -2%;
    border-radius: 4px;
    padding-left: 2%;
    padding-right: 2%;

    background-color: #f5f5f5;
    border: 1px solid #006AFF;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
}

.pagessidebar-body-names-page-title {
    min-width: 0px;
    width: 100%;
    max-width: 200px;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.pagessidebar-body-names-page-title-input {
    padding-top: 0px;
    padding-bottom: 0px;

    border-radius: 3px;
    background-color: white;
}

.pagessidebar-body-names-page-title-input:hover {
    background-color: white;
}

.pagessidebar-body-names-page-title-input:focus {
    outline: none;
    color: #006aef;
}

.pagessidebar-groups {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.pagessidebar-group-header {
    position: relative;

    display: grid;
    grid-template-columns: 1.5fr 20fr 2fr;
    align-items: center;

    padding-left: 2%;
    padding-right: 2%;

    font-size: var(--small-fs);
    font-weight: var(--large-fw);

    height: 40px;
}

.pagessidebar-group-header:hover {
    cursor: pointer;
    background-color: var(--revell);
}

.pagessidebar-group {
    width: 100%;
}

.pagessidebar-viewoptions {
    position: relative;
}

.pagessidebar-grid-drag-overlay {
    position: absolute;
    top: 0px;
    left: 0px;

    width: 100%;
    height: 100%;

    /*alice blue with alpha value 0.5*/
    background-color: rgba(255, 255, 255, 0.8);
    border: 1px solid black;
    border-radius: 5px;

    font-size: var(--small-fs);
    font-weight: var(--small-fw);
    color: var(--text-color);

    display: flex;
    align-items: center;
    justify-content: center;

    padding: 2%;
}

.pagessidebar-page-options-container {
    width: 190px !important;

    font-size: var(--xsmall-fs);
    font-weight: var(--xsmall-fw);
    color: var(--text-color);
}

.pagessidebar-page-options-section {
    display: grid;
    grid-template-columns: 1fr 10fr;
    align-items: center;
    gap: 4px;

    padding: 4px;
}

.pagessidebar-page-options-section:hover {
    cursor: pointer;
    background-color: var(--revell);
}

.pagessidebar-page-options-section-delete {
    display: grid;
    grid-template-columns: 1fr 10fr;
    align-items: center;
    gap: 4px;

    padding: 4px;

    color: var(--red);

    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.pagessidebar-page-options-section-delete:hover {
    cursor: pointer;
    background-color: var(--light-red);
}

.pagessidebar-page-options-section-deletesimilar {
    border-radius: 0px;
}

.pagessidebar-page-options-group {
    display: grid;
    grid-template-columns: 1fr 10fr 1fr;
    align-items: center;
    gap: 4px;

    padding: 4px;

    position: relative;
}

.pagessidebar-page-options-group:hover {
    cursor: pointer;
    background-color: var(--revell);
}

.pagessidebar-page-options-group-options {
    position: absolute;
    top: 0px;
    left: calc(100% - 2px);

    width: 200px !important;
    background-color: white;
    border: 1px solid lightgray;

    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;

    display: flex;
    flex-direction: column;
    align-items: center;

    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
}

.pagessidebar-page-options-group-options>div:not(:last-child) {
    border-bottom: 1px solid lightgray;
}

/*first need border radius*/
.pagessidebar-page-options-group-options>div:first-child {
    border-top-right-radius: 5px;
}

/*last need border radius*/
.pagessidebar-page-options-group-options>div:last-child {
    border-bottom-right-radius: 5px;
}

.pagessidebar-page-options-group-options-right {
    position: absolute;
    top: 0px;
    left: -200px;

    width: 200px !important;
    background-color: white;
    border: 1px solid lightgray;

    border-radius: 5px;

    display: flex;
    flex-direction: column;
    align-items: center;

    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
}

.pagessidebar-page-options-group-options-container {
    max-height: 200px;
    overflow-y: scroll;
}

.pagessidebar-page-options-group-options-right>div:not(:last-child) {
    border-bottom: 1px solid lightgray;
}

/*first need border radius*/
.pagessidebar-page-options-group-options-right>div:first-child {
    border-top-left-radius: 5px;
}

/*last need border radius*/
.pagessidebar-page-options-group-options-right>div:last-child {
    border-bottom-left-radius: 5px;
}

.pagessidebar-page-options-section-first {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    display: grid;
    grid-template-columns: 1fr 10fr;
    align-items: center;
    gap: 2px;
    padding-left: 4px;

    height: 30px;
}

.pagessidebar-page-options-section-first:hover {
    cursor: pointer;
    background-color: var(--revell);
}

.pagessidebar-page-options-section-name {
    display: grid;
    grid-template-columns: 10fr 1fr;
    align-items: center;
    gap: 2px;
    padding-right: 4px;

    height: 30px;

    border-bottom: 1px solid lightgray;
}

.pagessidebar-page-options-name-input {
    padding-left: 5px;
    border-radius: 4px;

    border-bottom-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;

    margin-top: -1px;
    margin-left: -1px;

    width: 100%;

    outline: none;
    border: 1px solid lightgray;
    border-right: none;

    height: 31px;
}

.pagessidebar-page-options-name-input:hover {
    border: 1px solid #006aef;
    cursor: text !important;
}

.pagessidebar-page-options-name-input:focus {
    border: 1px solid #006aef;
    background-color: aliceblue;
}

.pagessidebar-page-options-group-option {
    display: grid;
    grid-template-columns: 1fr 1fr 20fr;
    align-items: center;
    gap: 2px;
    padding: 4px;

    width: 200px !important;
    height: 30px !important;
    min-height: 30px !important;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    border-left: 1px solid lightgray;
    border-right: 1px solid lightgray;
}

.pagessidebar-page-options-group-option:hover {
    cursor: pointer;
    background-color: whitesmoke;
}

.pagessidebar-page-options-group-option-icon-selected {
    background-color: var(--bobyard-blue);
    border-radius: 50%;
    height: 5px;
    width: 5px;
}

.pagessidebar-page-options-group-search-container {
    width: 100%;

    display: grid;
    grid-template-columns: 1fr 10fr;
    align-items: center;
    gap: 2px;

    font-size: var(--small-fs);
    font-weight: var(--small-fw);
    color: var(--text-color);

    padding-left: 2px;

    height: 30px;
}

.pagessidebar-page-options-group-search-container:hover {
    cursor: text;
}

.pagessidebar-page-options-group-search-input {
    outline: none;
    padding: 2px;
    padding-left: 4px;

    border-top: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
    border-right: 1px solid lightgray;

    border-top-right-radius: 5px;

    margin-left: 1px;

    margin-top: -1px;
    height: 31px;

    width: 100%;
}

.pagessidebar-page-options-group-search-input:hover {
    padding-left: 3px;
    border: 1px solid #006aef;
}

.pagessidebar-page-options-group-search-input:focus {
    padding-left: 3px;
    border: 1px solid #006aef;
    background-color: aliceblue;
}

.pagessidebar-group-title-input {
    height: 40px;

    outline: none;
    border: 1px solid transparent;

    padding-left: 5px;
    margin-left: -6px;
}

.pagessidebar-group-title-input:hover {
    border: 1px solid #006aef;
    cursor: text !important;
}

.pagessidebar-group-title-input:focus {
    border: 1px solid #006aef;
}

.pagessidebar-group-gear {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 40px;
}

.pagessidebar-group-gear:hover {
    cursor: pointer;
    color: #006aff;
    background-color: var(--revell);
}

.pagessidebar-page-options-section-changecount {
    display: grid;
    grid-template-columns: 1fr 10fr 1fr;
    align-items: center;
    gap: 4px;

    padding: 4px;

    position: relative;

    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.pagessidebar-page-options-section-changecount:hover {
    cursor: pointer;
    background-color: var(--revell);
}

.pagessidebar-page-options-section-item {
    border-radius: 0px;
}

.pagessidebar-page-ai-prepped {
    position: absolute;
    top: 1px;
    right: 0px;

    z-index: 10;

    font-size: 0.8rem;

    color: #006aef;

    /*border: 1px solid black;*/
    border-radius: 4px;

    padding: 3px;
    padding-top: 0px;
    padding-bottom: 0px;

    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pagessidebar-page-options-group-option-text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    max-width: 80%;
}
.dashboard-createprojectmodal {
    display: flex;
    padding: 20px 25px;
    flex-direction: column;
    gap: 15px;
    flex-shrink: 0;
}

.dashboard-createprojectmodal-input-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;

    gap: 6px;
}

.dashboard-createprojectmodal-input-title {
    color: var(--Base-Black, #151515);

    /* Text Medium */
    /*font-family: "Maison Neue";*/
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    /* 137.5% */
    letter-spacing: -0.32px;
}

.dashboard-createprojectmodal-input {
    display: flex;
    height: 52px;
    padding: 8px 12px 6px 12px;
    align-items: center;
    gap: 8px;
    align-self: stretch;

    border-radius: 4px;
    border: 1px solid var(--Gray-400, #C6C6CA);
    background: var(--Base-White, #FFF);

    width: 100%;

    color: var(--Gray-600, #898990);

    /* Text Medium */
    /*font-family: "Maison Neue";*/
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    /* 137.5% */
    letter-spacing: -0.32px;
}

.dashboard-createprojectmodal-input:hover {
    border: 1px solid #006AFF;
}

.dashboard-createprojectmodal-input:focus {
    border: 1px solid #006AFF;
}

.dashboard-createprojectmodal-header {
    color: #000;
    font-family: "Inter";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    /* 140% */
    letter-spacing: -0.4px;
}

.dashboard-createprojectmodal-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding-bottom: 20px;

    width: 100%;
}

.dashboard-createprojectmodal-close {
    cursor: pointer;
    color: gray;

    border-radius: 5px;
    padding: 5px;
}

.dashboard-createprojectmodal-close:hover {
    color: black;
    background-color: var(--revell);
}

.dashboard-createprojectmodal-button-container {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}

.dashboard-createprojectmodal-description {
    height: 200px;
}

.dashboard-createprojectmodal-autocomplete-container {
    position: relative;
}

.dashboard-createprojectmodal-autocomplete-dropdown-container {
    position: absolute;
    top: 90%;
    left: 0%;

    width: 100%;
    max-height: 200px;

    overflow: scroll;

    background-color: white;
    border-radius: 5px;

    z-index: 100;

    border: 1px solid lightgray;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
    padding: 1%;
}

.dashboard-createprojectmodal-button-disabled {
    background-color: #D3D3D3;
    color: #A9A9A9;
    border: 1px solid #D3D3D3;

    border-radius: 4px;

    padding: 10px 16px;
    justify-content: center;
    align-items: center;
    gap: 4px;

    transition-duration: 0.3s;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    /* Button Medium */
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

.dashboard-createprojectmodal-button-disabled:hover {
    cursor: not-allowed;
}

.dashboard-createprojectmodal-suggestion-item {
    padding: 5px 10px;

    color: var(--Gray-600, #898990);

    /* Text Medium */
    /*font-family: "Maison Neue";*/
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    /* 137.5% */
    letter-spacing: -0.32px;
}

.dashboard-createprojectmodal-suggestion-item-active {
    padding: 5px 10px;

    color: var(--Gray-600, #898990);
    background-color: var(--revell);

    /* Text Medium */
    /*font-family: "Maison Neue";*/
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    /* 137.5% */
    letter-spacing: -0.32px;

    cursor: pointer;
}
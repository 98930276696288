.color-input{
    /*round circle*/
    width: 15px;
    height: 15px;

    border-radius: 50%;
    border: 1px solid lightgray;

    background-color: white;

    overflow: hidden;
}

.color-input:hover{
    cursor: pointer;

    border-color: black;
}

.color-input::-webkit-color-swatch {
    border: none;
    border-radius: 50%;
}
.takeoff-minimap-container {
    position: fixed;
    bottom: 5px;

    z-index: 1;

    overflow: hidden;

    height: fit-content;

    border-radius: 5px;

    padding: 0px;

    box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.3);
}

.takeoff-minimap-expand {
    position: absolute;
    bottom: 1px;

    z-index: 1;
}

.takeoff-minimap-minimize {
    position: relative;
    top: -1px;
    left: 0px;

    z-index: 10;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
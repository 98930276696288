.aisidebar-count-container {
    overflow-y: scroll;
    height: calc(100vh - 80px);

    user-select: none;

    padding-bottom: 50%;

    /*font-family: 'Maison Neue';*/
}

.aisidebar-length-container {
    overflow-y: scroll;
    height: 100%;

    user-select: none;
}

.aisidebar-area-container {
    overflow-y: scroll;
    height: 100%;

    user-select: none;

    padding: 2%;

    /*font-family: 'Maison Neue';*/
}

.aisidebar-count-group {
    padding: 10px;
    padding-top: 0px;
    padding-bottom: 0px;

    display: grid;
    grid-template-columns: 1fr 10fr;
}

.aisidebar-count-group-progress-first {
    padding-left: 10px;
    margin-top: 20px;

    border-right: 2px solid gainsboro;
    width: 2px;
    margin-right: 2px;

    position: relative;
}

.aisidebar-count-group-progress-dot-first {
    position: absolute;
    left: 5px;

    width: 12px;
    height: 12px;

    border-radius: 50%;
    border: 2px solid gainsboro;
    background-color: white;
}

.aisidebar-count-group-progress-top {
    position: absolute;
    top: 0px;
    height: 20px;

    border-right: 2px solid gainsboro;
    width: 2px;
    margin-right: 2px;
}

.aisidebar-count-group-progress-bottom {
    position: absolute;
    top: 20px;
    height: calc(100% - 20px);

    border-right: 2px solid gainsboro;
    width: 2px;
    margin-right: 2px;
}

.aisidebar-count-group-progress {
    padding-left: 10px;
    padding-top: 20px;

    position: relative;
}

.aisidebar-count-group-progress-dot {
    position: absolute;
    left: 5px;

    width: 12px;
    height: 12px;

    border-radius: 50%;
    border: 2px solid gainsboro;
    background-color: white;
}

.aisidebar-count-group-progress-last {
    padding-left: 10px;
    padding-top: 20px;
    height: 20px;

    border-right: 2px solid gainsboro;
    width: 2px;
    margin-right: 2px;

    position: relative;
}

.aisidebar-count-group-content {
    padding-top: 20px;
    padding-bottom: 20px;
}

.aisidebar-count-group-header {
    margin-top: -5px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 3%;
    padding-left: 1%;
}

.aisidebar-count-legend-create-button {
    border-radius: 5px;
    padding: 5px;
}

.aisidebar-count-legend-create-button:hover {
    cursor: pointer;
    background-color: aliceblue;
    color: #006AFF;
}

.aisidebar-count-group-title {
    font-size: var(--small-fs);
    font-weight: 400;
    color: black;

    /*font-family: "Maison Neue";*/
}

.aisidebar-count-group-header-help {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid gainsboro;

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: var(--xsmall-fs);
    font-weight: var(--xsmall-fw);
    color: var(--text-color);
}

.aisidebar-count-group-header-help:hover {
    cursor: pointer;
    background-color: aliceblue;
    color: #006AFF;
}

.aisidebar-count-group-help {
    font-size: var(--xsmall-fs);
    font-weight: var(--xsmall-fw);
    color: var(--text-color);

    display: flex;
    flex-direction: column;
    gap: 5px;
}

.aisidebar-count-group-2choices {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.aisidebar-count-group-3choices {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.aisidebar-count-group-3choices-center {
    border: 1px solid gainsboro;
    border-color: var(--text-color);
}

.aisidebar-count-group-choice {
    font-size: var(--small-fs);
    font-weight: var(--medium-fw);
    color: var(--text-color);

    height: 30px;

    display: flex;
    justify-content: center;
    align-items: center;

    text-align: center;
}

.aisidebar-count-group-choice:hover {
    cursor: pointer;
    background-color: aliceblue !important;
    color: #006AFF;
}

.aisidebar-count-group-choice-disabled {
    background-color: white;
    color: silver;
    border-color: silver !important;
}

.aisidebar-count-group-choice-disabled:hover {
    cursor: not-allowed !important;
    background-color: white !important;
    color: silver !important;
    border-color: silver !important;
}

.aisidebar-count-group-2choices-left {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border: 1px solid gainsboro;
    border-color: var(--text-color);
}

.aisidebar-count-group-2choices-right {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border: 1px solid gainsboro;
    border-color: var(--text-color);
}

.aisidebar-count-group-choice-active {
    background-color: aliceblue;
    color: var(--bobyard-blue);
    border-color: var(--bobyard-blue);
}

.aisidebar-count-group-no-legends {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;

    border: 1px solid gainsboro;
    border-radius: 3px;
    padding: 5px;
}

.aisidebar-count-group-no-legends:hover {
    cursor: pointer;
    background-color: aliceblue;
    color: #006AFF;
}

.aisidebar-count-auto-count-option {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 1%;

    align-items: center;
}

.aisidebar-count-group-body {
    padding-left: 1%;
    padding-right: 3%;

    display: flex;
    flex-direction: column;
    gap: 5px;

    padding-top: 10px;
}

.aisidebar-count-group-legend {
    padding-top: 5px;
}

.aisidebar-count-auto-count-button {
    padding: 5px 20px;
    border-radius: 5px;
    text-align: center;

    border: 1px solid gainsboro;

    font-size: var(--small-fs);
    font-weight: var(--medium-fw);
    color: var(--text-color);
}

.aisidebar-count-auto-count-button:hover {
    cursor: not-allowed;
}

.aisidebar-count-auto-count-button-active {
    border: 1px solid #006AFF;
    background-color: #006AFF;
    color: white;
}

.aisidebar-count-auto-count-button-running {
    border: 1px solid #006AFF;
    background-color: aliceblue;
    color: #006AFF;
}

.aisidebar-count-auto-count-button-running:hover {
    cursor: not-allowed;
}

.aisidebar-count-auto-count-button-active:hover {
    background-color: aliceblue;
    color: #006AFF;
    cursor: pointer;
}

.aisidebar-count-auto-count-button-container {
    display: flex;
    justify-content: center;
}

.aisidebar-count-auto-count-button-disabled {
    color: silver;
    border-color: silver;
    background-color: white;
}

.aisidebar-count-auto-count-button-disabled:hover {
    cursor: not-allowed;
}

.aisidebar-count-auto-count-select {
    padding: 5px;
    border-radius: 5px;
    background-color: aliceblue;
    outline: none;
    border: 1px solid transparent;

    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.aisidebar-count-auto-count-select:hover {
    border: 1px solid #006AFF;
    cursor: pointer;
}

.aisidebar-count-auto-count-select option {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.aisidebar-count-legend-header {
    display: grid;
    grid-template-columns: 10fr 1fr;
    align-items: center;
    gap: 5px;

    border-radius: 5px;
    padding: 3px 10px;
}

.aisidebar-count-legend-header:hover {
    background-color: aliceblue;
    cursor: pointer;
}

.aisidebar-count-legend-name {
    width: 100%;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.aisidebar-count-legend {
    border-radius: 5px;

    width: 100%;

    border: 1px solid lightgray;
}

.aisidebar-body-container {
    height: 100%;
}

.aisidebar-count-legends-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
}

.aisidebar-count-legends {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.aisidebar-count-legend-entry-text {
    font-size: 0.75em;

    display: flex;
    align-items: center;

    background-color: inherit;

    max-height: 5em;

    outline: none;
}

.aisidebar-count-legend-entry-text:hover {
    cursor: pointer;
    color: #006AFF;
}

.aisidebar-count-legend-entries-preview {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(50px, 1fr));
    padding: 5px;
}

.aisidebar-count-legend-preview-entry {
    display: flex;
    justify-content: center;
    align-items: center;
}

.aisidebar-count-legend-preview-entry-image {
    max-width: 40px;
    max-height: 40px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.aisidebar-count-legend-entry-text-edit {
    font-size: 0.75em;
    padding-left: 5px;

    line-height: 1.5em;

    max-height: 5em;

    margin-left: -5px;

    cursor: text;
}

.aisidebar-count-legend-button {
    border-radius: 5px;
}

.aisidebar-count-legend-button:hover {
    cursor: pointer;
    background-color: aliceblue;
    color: #006AFF;
}

.aisidebar-count-legend-buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2px;
}

.aisidebar-count-legend-buttons-empty {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.aisidebar-count-legends-header-buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.ailegend-popup-legend-no-entries {
    padding: 5px;
    padding-left: 10px;
}

.aisidebar-count-legend-settings {
    padding-top: 2px;
    padding-bottom: 2px;

    font-size: var(--xsmall-fs);
    font-weight: var(--xsmall-fw);
    color: var(--text-color);
}

.aisidebar-count-legend-settings-group-item-disabled {
    display: flex;
    justify-content: left;
    gap: 5px;
    align-items: center;

    padding-left: 4px;
    padding-right: 4px;

    color: gainsboro;
}

.aisidebar-count-legend-settings-group-item-disabled:hover {
    cursor: not-allowed;
    background-color: whitesmoke;
    color: gainsboro;
}

.aisidebar-count-legend-settings-group-item {
    display: flex;
    justify-content: left;
    gap: 5px;
    align-items: center;

    padding-left: 4px;
    padding-right: 4px;
}

.aisidebar-count-legend-settings-group-item:hover {
    cursor: pointer;
    background-color: aliceblue;
    color: #006AFF;
}

.aisidebar-count-legend-settings-hr {
    height: 1px;
    margin: 5px;
}

.aisidebar-count-legend-settings-group-item-delete {
    display: flex;
    justify-content: left;
    gap: 5px;
    align-items: center;

    padding-left: 4px;
    padding-right: 4px;
}

.aisidebar-count-legend-settings-group-item-delete:hover {
    cursor: pointer;
    background-color: lightpink;
    color: red;
}


.aisidebar-legend-entry-menu-item {
    width: 100%;

    color: var(--text-color);

    display: flex;
    justify-content: left;
    gap: 5px;
    align-items: center;

    padding: 4px;
    border-radius: 4px;
}

.aisidebar-legend-entry-menu-item:hover {
    cursor: pointer;
    background-color: aliceblue !important;
    color: #006AFF !important;
}

.aisidebar-legend-entry-menu-delete {
    width: 100%;

    display: flex;
    justify-content: left;
    gap: 5px;
    align-items: center;

    padding: 4px;
    border-radius: 4px;
}

.aisidebar-legend-entry-menu-delete:hover {
    cursor: pointer;
    background-color: lightpink !important;
    color: red !important;
}

.aisidebar-count-legend-name-edit {
    width: 100% !important;
    min-width: 0px;

    padding-left: 4px;
    margin-left: -4px;
}

.aisidebar-legend-entry-menu-attribute-container {
    width: 100%;

    position: relative;
}

.aisidebar-legend-entry-menu-attributes {
    position: absolute;

    top: 0%;
    z-index: 1000;
    flex-direction: column;
    background-color: white;
    border: 1px solid gainsboro;
    border-radius: 5px;
    padding: 5px;
}

.aisidebar-legend-entry-menu-attribute {
    min-width: 250px;

    color: var(--text-color);

    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 10px;
    align-items: center;

    padding: 4px;
    border-radius: 4px;
}

.aisidebar-legend-entry-menu-attribute:hover {
    cursor: pointer;
    background-color: aliceblue !important;
    color: #006AFF !important;
}

.aisidebar-legend-entry-menu-attribute-title {
    font-size: var(--small-fs);
    font-weight: var(--medium-fw);
}

.aisidebarcount-legends-column-modal-row {
    display: grid;
    align-items: center;
}

.aisidebarcount-legends-column-modal-row-entry {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.aisidebarcount-legends-column-modal-text {
    font-size: var(--small-fs);
    font-weight: var(--small-fw);

    margin-bottom: 5px;
}

.aisidebarcount-legends-column-modal-option {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    font-size: var(--small-fs);
    font-weight: var(--small-fw);

    padding: 5px;
    border-radius: 5px;

    background-color: whitesmoke;

    width: 90%;

    margin-bottom: 10px;
}

.aisidebarcount-legends-column-modal-option:hover {
    cursor: pointer;
    background-color: aliceblue;
    color: #006AFF;
}

.aisidebar-body-message {
    padding: 10px;
}
.settings-container{
    padding-left: 20%;
    padding-right: 20%;
    padding-top: 5%;
    padding-bottom: 20%;
    
    height: calc(100vh - 64px);

    overflow: scroll;

    background-color: #F9F9FB;
}

.settings-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.settings-title{
    font-size: var(--xlarge-fs);
    font-weight: var(--xlarge-fw);
    color: var(--text-color);
}

.settings-body{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5%;
    gap: 15px;
}

.settings-section{
    width: 100%;
    display: grid;
    grid-template-columns: 5fr 4fr;
    gap: 20px;

    background-color: white;
    color: var(--text-color);

    border-radius: 5px;

    box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.1);

    padding: 2%;
}

.settings-section-title{
    font-size: var(--large-fs);
    font-weight: var(--large-fw);
}

.settings-section-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.settings-section-details{
    padding: 2%;
    gap: 2%;

    border-radius: 5px;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.1);
}

.settings-section-item{
    display: grid;
    grid-template-columns: 3fr 1fr;
    align-items: center;
    gap: 5px;

    font-size: var(--medium-fs);
    font-weight: var(--medium-fw);
}

.settings-section-item-input-number{
    padding: 2px;
    padding-left: 10px;
    border-radius: 5px;

    width: 100%;

    border: 1px solid var(--revell);
}

.settings-section-item-input{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.settings-section-item-input-number:hover{
    outline: none;
    border: 1px solid #006AFF;
}

.settings-section-item-input-number:focus{
    outline: none;
    background-color: aliceblue;
    border: 1px solid #006AFF;
}

.settings-section-item-switch{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.settings-section-body{
    margin-top: 5%;

    display: flex;
    flex-direction: column;
    gap: 5px;
}

.settings-section-description-header{
    font-size: var(--small-fs);
    font-weight: bold;
}

.settings-section-description{
    margin-top: 4%;

    font-size: var(--small-fs);
    font-weight: var(--small-fw);
}
.chat-container {
  height: calc(100vh - 64px);

  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
}

.chat-container-file {
  display: grid;
  grid-template-columns: 3fr 2fr;

  height: calc(100vh - 64px);
}

.chat-left {
  overflow: hidden;

  display: grid;
  grid-template-rows: 64px 1fr 80px;
  height: 100%;
  border-left: 1px solid #e0e0e0;
}

.chat-left-file {
  overflow: hidden;

  display: grid;
  grid-template-rows: 64px 1fr 80px;
}

.chat-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #f5f5f5;
  border-bottom: 1px solid #e0e0e0;

  padding-left: 5%;
  padding-right: 5%;
}

.chat-title {
  font-size: 20px;
  font-weight: 600;
}

.chat-description {
  font-size: 14px;
  color: #777;

  padding-left: 5%;
  padding-right: 5%;

  display: flex;
  flex-direction: column;
  gap: 5px;
}

.chat-messages {
  height: calc(100vh - 64px - 64px - 100px);
  overflow-y: auto;
  padding: 10px 20px;
  background-color: white;

  padding-left: 5%;
  padding-right: 5%;
}

.chat-input-footer {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
  padding: 10px 20px;
  background-color: #f5f5f5;
  border-top: 1px solid #e0e0e0;

  padding-left: 5%;
  padding-right: 5%;
}

.chat-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  outline: none;
}

.chat-input:hover {
  border-color: #006aff;
}

.chat-input:focus {
  border-color: #006aff;
  background-color: aliceblue;
}

.chat-send-button {
  padding: 10px 20px;
  background-color: #006aff;
  border: 1px solid #006aff;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  outline: none;

  width: 125px;

  display: flex;
  justify-content: center;
  align-items: center;

  transition: all 0.3s;
}

.chat-send-button:hover {
  border: 1px solid #006aff;
  background-color: aliceblue;
  color: #006aff;
}

.chat-send-button-inactive {
  padding: 10px 20px;
  background-color: #e0e0e0;
  border: 1px solid #e0e0e0;
  color: white;
  border-radius: 5px;
  cursor: not-allowed;
  outline: none;

  width: 125px;

  display: flex;
  justify-content: center;
  align-items: center;

  transition: all 0.3s;
}

.chat-send-button-inactive:hover {
  border: 1px solid #e0e0e0;
  background-color: #e0e0e0;
  color: white;

  cursor: not-allowed;
}

.chat-message {
  margin: 10px 0;
  border-radius: 5px;

  padding: 10px;

  display: flex;
  flex-direction: column;
  gap: 5px;
}

.chat-message-bot {
  background-color: aliceblue;
}

.chat-message-user {
  background-color: whitesmoke;
  margin-left: 40%;
}

.chat-loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.chat-message-details {
  display: flex;
  justify-content: space-between;
}

.chat-message-time {
  font-size: 12px;
  color: #777;
}

.chat-message-sender {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;

  font-weight: bold;
}

.chat-message-contexts {
  font-size: 14px;
}

.chat-message-context {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;

  background-color: gainsboro;
  padding: 5px 10px;
  border-radius: 3px;

  width: fit-content;
}

.chat-message-context:hover {
  background-color: lightgray;
  cursor: pointer;
}

.chat-files {
  display: flex;
  flex-direction: column;
  gap: 10px;

  width: 60%;
}

.chat-right {
  overflow: hidden;

  background-color: #f5f5f5;

  position: relative;
}

.chat-file {
  display: grid;
  grid-template-columns: 4fr 1fr;
  align-items: center;
  gap: 5px;

  padding: 5px;
}

.chat-file:hover {
  cursor: pointer !important;
  background-color: aliceblue;
}

.chat-file-status {
  display: flex;
  justify-content: right;
  align-items: center;
  gap: 5px;
}

.chat-file-name {
  font-size: 14px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.chat-file-iframe {
  width: 100%;
  height: 100%;
}

.chat-file-image {
  width: 100%;
  height: calc(100vh - 64px);
  object-fit: contain;

  overflow: hidden;
  display: flex;
}

.chat-back-button {
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
  outline: none;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  transition: all 0.3s;
}

.chat-back-button:hover {
  color: #006aff;
  background-color: aliceblue;
}

.chat-header-files-dropdown {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;

  padding: 5px;

  user-select: none;
}

.chat-header-files-dropdown:hover {
  cursor: pointer;
  background-color: aliceblue;
  color: #006aff;
}

.chat-files-dropdown-container {
  padding: 5px;
}

.chat-files-dropdown-header {
  padding-bottom: 5px;
}

.chat-header-buttons {
  display: flex;
  gap: 20px;
}

.chat-sidebar {
  overflow: hidden;
  height: 100%;
  border-right: 1px solid #e0e0e0;
}

.chat-no-file {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.chat-navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 10px 20px;

  border-bottom: 1px solid gainsboro;
}

.takeoff-container{
    overflow: hidden;
    padding-top: 40px;

    height: 100vh;

    position: relative;
    z-index: 1;
}

.takeoff-toastcontainer{
    margin-top: 40px;
}

.takeoff-toast-close-icon{
    text-decoration: none;
    height: 30px;
    line-height: 30px;
    font-weight: bold;
    font-style: normal;
}

.takeoff-toast-close-icon:hover{
    color: red;
    cursor: pointer;
}
.active-button {
    border-radius: 4px;
    background-color: var(--bobyard-blue);
    color: white;
    border: 1px solid var(--bobyard-blue);

    padding: 10px 16px;
    justify-content: center;
    align-items: center;
    gap: 4px;

    transition-duration: 0.3s;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    color: var(--Base-White, #FFF);

    /* Button Medium */
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 150% */
}

.active-button:hover {
    cursor: pointer;
    background: var(--Blue-700, #0045ad);
}

.active-button-small {
    border-radius: 4px;
    background-color: #006AFF;
    color: white;
    border: 1px solid #006AFF;

    padding: 1px;
    padding-left: 5px;
    padding-right: 5px;
    transition-duration: 0.3s;

    font-size: 0.8rem;
}
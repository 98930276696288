.takeoffedithistory-body {
    width: 100vw;
    height: 100vh;
    overflow: hidden;

    display: flex;
    flex-direction: row;
    justify-content: center;
}

.takeoffedithistory-takeoff-render {
    width: 80vw;
}

.takeoffedithistory-snapshot-list {
    width: 20vw;
    height: calc(100vh - 40px);
    overflow-y: hidden;

    position: absolute;
    top: 40px;
    right: 0;

    background-color: white;

    border-left: 1px solid #e0e0e0;
}

.takeoffedithistory-snapshot-list-body {
    height: calc(100vh - 50px);
    overflow-y: auto;

    padding-bottom: 50%;
}

.takeoffedithistory-loading-container {
    height: calc(100vh) !important;
    max-height: calc(100vh) !important;
    min-height: calc(100vh) !important;
    overflow: hidden;

    background-color: rgba(115, 147, 179, 0.3);

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: larger;
}

.takeoffedithistory-snapshot-list-folder {
    /*sticky*/
    position: sticky;
    top: 0px;

    display: grid;
    grid-template-columns: 1fr 2fr 2fr;
    align-items: center;

    cursor: pointer;

    padding: 5px;

    background-color: aliceblue;
}

.takeoffedithistory-snapshot-list-folder:hover {
    background-color: #e0e0e0;
}

.takeoffedithistory-snapshot-list-folder-edit-count {
    text-align: right;
    padding-right: 5px;

    font-weight: bold;
}

.takeoffedithistory-snapshot-list-item {
    cursor: pointer;

    padding: 5px;
    padding-left: 20px;

    background-color: white;

    border: 1px solid white;
}

.takeoffedithistory-snapshot-list-item:hover {
    background-color: #e0e0e0;
    border-color: #e0e0e0;
}

.takeoffedithistory-snapshot-list-item-active {
    background-color: aliceblue;
    border: 1px solid var(--bobyard-blue);

    color: var(--bobyard-blue);
}

.takeoffedithistory-snapshot-list-item-active:hover {
    background-color: aliceblue;

    color: var(--bobyard-blue);

    border-color: var(--bobyard-blue);

    cursor: default;
}

.takeoffedithistory-snapshot-list-item-button {
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 25%;
    padding: 10px;
}

.takeoffedithistory-snapshot-list-item-button:hover {
    cursor: pointer;

    background-color: var(--bobyard-blue);
    color: white;
}

.takeoffedithistory-no-snapshots {
    display: flex;
    justify-content: center;
    align-items: center;

    padding-top: 50px;
}

.takeoffedithistory-no-snapshot-selected {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100vw;
    height: 100vh;

    background-color: rgba(115, 147, 179, 0.3);
}

.takeoffedithistory-navbar-container {
    position: absolute;
    top: 0;
    left: 0;

    width: 100vw;

    background-color: white;

    z-index: 1000;

    height: 40px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    border-bottom: 1px solid #e0e0e0;
}

.takeoffedithistory-project-name-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
}

.takeoffedithistory-edit-button {
    margin-right: 10px;
}

.takeoffedithistory-edit-button {
    border-radius: 5px;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;

    border: 1px solid #e0e0e0;
}

.takeoffedithistory-edit-button:hover {
    cursor: pointer;

    background-color: #e0e0e0;
}
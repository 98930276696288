.switch-container{
    min-width: 0px;
    min-height: 0px;
}

.switch-checkbox {
    height: 0;
    width: 0;
    visibility: hidden;
}

.switch-label {
    position: relative;

    margin-top: 0px;

    cursor: pointer;

    width: 40px;
    height: 20px;

    background: grey;
    border-radius: 100px;

    transition: background-color .2s;
}

.switch-label .switch-button {
    content: '';
    position: absolute;
    top: 1px;
    left: 2px;
    width: 18px;
    height: 18px;
    border-radius: 45px;
    transition: 0.2s;
    background: #fff;
    box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.switch-checkbox:checked+.switch-label .switch-button {
    left: calc(100% - 2px);
    transform: translateX(-100%);
}

.switch-label:active .switch-button {
    width: 25px;
}
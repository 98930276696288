.default-icon-button{
    border-radius: 5px;
  
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    transition-duration: 0.3s;

    outline: none;
}
  
.default-icon-button:hover{
    cursor: pointer;

    background-color: aliceblue;

    color: #006aef;
}
  
.default-icon-button-small{
    border-radius: 4px;
  
    padding: 1px;
    padding-left: 5px;
    padding-right: 5px;
    transition-duration: 0.3s;

    font-size: 0.8rem;
}

.default-icon-button-disabled{
    color: lightgray;
}

.default-icon-button-disabled:hover{
    background-color: white;
    cursor: not-allowed;
}

.default-icon-button-active{
    background-color: #006aef;
    color: white;
}

.default-icon-button-active:hover{
    background-color: #006aef;
    color: white;
    cursor: default !important;
}
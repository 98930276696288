.error-boundary-container{
    height: 100vh;
    width: 100vw;

    padding: 10% 20%;

    background-color: whitesmoke;

    display: flex;
    flex-direction: column;
    gap: 5%;
}

.error-boundary-header{
}

.error-boundary-title{
    font-size: 2rem;
    font-weight: bold;
}

.error-boundary-header-message{
    font-size: 1.5rem;
}

.error-boundary-content{

}

.error-boundary-message{
    font-size: 1.2rem;
}

.error-boundary-link{
    color: blue;
}

.error-boundary-link:hover{
    text-decoration: underline;
    cursor: pointer;
}
.estimate-table-column-settings-header-container {
    border-bottom: 1px solid gainsboro;

    height: 40px;
    display: flex;
    align-items: center;
}

.estimate-table-column-settings-header {
    display: grid;
    grid-template-columns: 5fr 1fr 5fr;
    align-items: center;

    width: 30%;
}

.estimate-table-column-settings-header-item {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    height: 100%;
}

.estimate-table-column-settings {
    display: grid;
    grid-template-columns: 5fr 1fr 5fr;
    align-items: center;

    width: 30%;

    height: 30px;
}

.estimate-table-column-settings-item-container {
    display: flex;
    justify-content: left;
    align-items: center;
    height: 40px;

    width: 100%;

    border-bottom: 1px solid gainsboro;
}

.estimaterevamp-comment-thread-container {
    width: 100%;

    background-color: whitesmoke;
    padding: 5px 10px;
    border: 1px solid whitesmoke;

    border-radius: 5px;
}

.estimaterevamp-comment-thread-container:hover {
    background-color: #f0f0f0;
    cursor: pointer;
    border: 1px solid gainsboro;
}

.estimaterevamp-comment-thread-title {
    font-size: 16px;
}

.estimaterevamp-comment-thread-content {
    font-size: 14px;
}

.estimaterevamp-comment-edit-container{
    padding: 5px 10px;
    background-color: aliceblue;
    border-radius: 5px;

    display: flex;
    flex-direction: column;
}
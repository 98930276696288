@tailwind base;
@tailwind components;
@tailwind utilities;

.modal-backdrop {
  opacity: 0.3 !important;
}

@layer utilities {
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }
}

.contexify_theme-bobyard-light {
  --contexify-menu-bgColor: white;
  --contexify-separator-color: rgba(0, 0, 0, 0.2);
  --contexify-item-color: #333;
  --contexify-activeItem-color: #fff;
  --contexify-activeItem-bgColor: white;
  --contexify-rightSlot-color: #6f6e77;
  --contexify-activeRightSlot-color: #fff;
  --contexify-arrow-color: #6f6e77;
  --contexify-activeArrow-color: #fff;

  --contexify-menu-minWidth: 220px;
  --contexify-menu-padding: 5px;
  --contexify-menu-radius: 5px;
  --contexify-menu-bgColor: #fff;
  --contexify-menu-shadow: 1px 2px 2px rgba(0, 0, 0, 0.1), 2px 4px 4px rgba(0, 0, 0, 0.1), 3px 6px 6px rgba(0, 0, 0, 0.1);
  --contexify-menu-negatePadding: var(--contexify-menu-padding);

  --contexify-separator-color: rgba(0, 0, 0, 0.2);
  --contexify-separator-margin: 5px;
  --contexify-itemContent-padding: 0px;

  --contexify-menu-bgColor: white;
  --contexify-separator-color: rgba(0, 0, 0, 0.2);
  --contexify-item-color: #333;
  --contexify-activeItem-color: #fff;
  --contexify-rightSlot-color: #6f6e77;
  --contexify-activeRightSlot-color: #fff;
  --contexify-arrow-color: #6f6e77;
  --contexify-activeArrow-color: #fff;
}

/*removes arrows on number fields Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

:root {
  /*
  --font-family: 'Lexend Deca';*/
  --font-family: 'Inter', sans-serif;

  --xlarge-fs: 32px;
  --xlarge-fw: 700;

  --large-fs: 22px;
  --large-fw: 600;

  --medium-fs: 20px;
  --medium-fw: 300;

  --small-fs: 16px;
  --small-fw: 300;

  --xsmall-fs: 14px;
  --xsmall-fw: 300;

  --xxsmall-fs: 12px;

  --bobyard-blue: #006aff;
  --alice-blue: #f0f8ff;
  --revell: #eeeeee;
  --fill-gray-blue: #f9f9fb;
  --text-color: #525252;
  --white: #ffffff;
  --red: #ff3333;
  --light-red: #ffcccc;

  --border-radius: 5px;
  --border-width: 1px;
}

body {
  font-family: var(--font-family) !important;
}

.tooltip-container {
  position: relative;
  isolation: isolate !important;
  z-index: 99999999 !important;
}

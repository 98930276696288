.createaerialpage-header {
    position: fixed;
    z-index: 100;
    top: 0px;
    left: 0px;

    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    align-items: center;

    gap: 5px;

    padding-left: 24px;
    padding-right: 24px;
    background-color: white;
    border-bottom: 1px solid var(--revell);

    width: 100%;
    height: 64px;
}

.createaerialpage-header-search {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.createaerialpage-map {
    width: 100vw;
    height: 100vh;
}

.createaerialpage-box {
    position: fixed;
    z-index: 10;

    width: 50vw;
    height: 50vw;

    left: 50%;
    top: 50%;

    transform: translate(-50%, -50%);

    border: 3px solid var(--bobyard-blue);
    border-radius: 5px;

    pointer-events: none;
}

.createaerialpage-header-capture-button-conatiner {
    display: flex;
    align-items: center;
    justify-content: right;
}

.createaerialpage-header-capture-button {
    background-color: var(--bobyard-blue);
    color: white;
    border: 1px solid var(--bobyard-blue);
    border-radius: 4px;

    height: 44px;

    display: flex;
    align-items: center;
    justify-content: center;

    padding: 10px 16px;
}

.createaerialpage-header-capture-button:hover {
    cursor: pointer;
    background-color: aliceblue;
    color: var(--bobyard-blue);

    transition: background-color 0.3s;
}

.createaerialpage-search-input {
    border: 1px solid var(--revell);

    padding-left: 10px;

    height: 40px;
    width: 400px;

    font-size: var(--small-fs);
    font-weight: var(--medium-fw);

    border-radius: 3px;

    outline: none;
}

.createaerialpage-search-input:hover {
    cursor: text;
    border-color: var(--bobyard-blue);
}

.createaerialpage-search-input:focus {
    border-color: var(--bobyard-blue);
    background-color: aliceblue;
}

.createaerialpage-search-clear {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 40px;
    height: 40px;

    border: 1px solid var(--revell);
    border-radius: 3px;

    font-size: var(--small-fs);
    font-weight: var(--medium-fw);

    background-color: white;

    cursor: pointer;
}

.createaerialpage-search-clear:hover {
    background-color: var(--revell);
}

.createaerialpage-autocomplete-container {
    position: relative;
}

.createaerialpage-autocomplete-dropdown-container {
    position: absolute;
    top: 110%;
    left: 0%;

    width: 100%;
    max-height: 200px;

    overflow: scroll;

    background-color: white;
    border-radius: 3px;

    z-index: 100;

    border: 1px solid lightgray;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
    padding: 1%;
}

.createaerialpage-box {
    position: fixed;
    z-index: 999;

    border: 3px solid var(--bobyard-blue);
    border-radius: 5px;
    pointer-events: none;
}

.createaerialpage-option-link {
    width: fit-content;
}

.createaerialpage-back {
    /*default button style*/

    padding: 10px 20px;
    border-radius: 5px;

    display: flex;
    align-items: center;
    justify-content: left;
    gap: 5px;

    width: fit-content;

    border: 1px solid var(--revell);
    border-radius: 5px;
}

.createaerialpage-back:hover {
    cursor: pointer;
    background-color: aliceblue;
    color: var(--bobyard-blue);

    transition: background-color 0.3s;
}
.deletemeasurementmodal-title {
    color: red;
}

.deletemeasurementmodal-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 5px;
    align-items: center;
}

.deletemeasurementmodal-row-name {
    font-weight: bold;
}

.deletemeasurementmodal-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    gap: 5px;

    color: red;
}
.dragging-placeholder{
    font-size: 0.8em;
    border-radius: 5px;

    background-color: aliceblue;
    border: 1px solid #006afe;

    padding: 2%;
    padding-top: 1%;
    padding-bottom: 1%;
}
.database-container {
    height: 100vh;
    overflow: hidden;
}

.database-navbar {
    height: 41px;
    width: 100%;
    background-color: white;

    display: flex;
    justify-content: space-between;
    align-items: center;

    border-bottom: 1px solid gainsboro;
}

.database-navbar-title {
    padding-left: 10px;
    padding-right: 10px;

    font-size: var(--small-fs);
    font-weight: var(--small-fw);
    color: var(--text-color);

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.database-navbar-logo-container {
    height: 40px;
    padding-left: 10px;
    padding-right: 10px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.database-navbar-logo-container:hover {
    cursor: pointer;
    background-color: var(--revell);
}

.database-navbar-logo {
    height: 26px;
}

.database-navbar-items {
    display: flex;
    align-items: center;
}

.database-navbar-items-group {
    display: flex;
    align-items: center;
    gap: 20px;
}

.database-navbar-item {
    height: 40px;
    width: 40px;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;

    font-size: var(--small-fs);
    font-weight: var(--small-fw);
}

.database-navbar-item:hover {
    cursor: pointer;

    color: var(--bobyard-blue);
    background-color: var(--revell);
}

.database-navbar-item-delete {
    color: var(--red);
}

.database-navbar-item-delete:hover {
    color: var(--red);
    background-color: var(--light-red);
}

.database-navbar-item-disabled {
    color: lightgray;
}

.database-navbar-item-disabled:hover {
    cursor: not-allowed;

    color: lightgray;
    background-color: white;
}

.database-navbar-search {
    display: flex;
    align-items: center;
    gap: 10px;
}

.database-navbar-search-container {
    display: flex;
    align-items: center;
}

.database-navbar-search-input {
    height: 30px;
    width: 300px;

    padding-left: 5px;

    border: 1px solid var(--revell);
    border-right: 0px;

    border-radius: 2px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;

    background-color: white;

    outline: none;

    color: var(--bobyard-blue);
}

.database-navbar-search-input:hover {
    border-color: var(--bobyard-blue);
    cursor: text !important;
}

.database-navbar-search-input:hover+.database-navbar-search-clear {
    border-color: var(--bobyard-blue);
}

.database-navbar-search-input:focus {
    background-color: aliceblue;
    border-color: var(--bobyard-blue);
}

.database-navbar-search-input:focus+.database-navbar-search-clear {
    border-color: var(--bobyard-blue);
    background-color: aliceblue;
}

.database-navbar-search-clear {
    height: 30px;
    width: 30px;

    display: flex;
    align-items: center;
    justify-content: center;

    border: 1px solid var(--revell);
    border-left: 0px;

    border-radius: 2px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;

    background-color: white;

    outline: none;
}

.database-navbar-search-clear:hover {
    cursor: pointer;
    background-color: var(--revell);
}

.vendor-quotes-columns {
    display: flex;
    flex-direction: row;

    height: calc(80vh - 69px);
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;

    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    background-color: #F8F8F8;
}

.database-columns {
    display: flex;
    flex-direction: row;

    min-height: calc(100vh - 40px) !important;
    height: calc(100vh - 40px) !important;
    width: 100% !important;
    overflow: scroll;
}

.database-column {
    display: flex;
    flex-direction: column;

    z-index: 1;
}

.vendorquotes-column-header-input-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    background-color: var(--revell);

    height: 40px;

    padding: 10px;
    padding-right: 0px;

    font-size: var(--small-fs);
    font-weight: var(--small-fw);

    margin-bottom: 3px;
}

.vendorquotes-column-header-input {
    margin-left: -10px;
    padding-left: 10px;

    height: 40px;

    outline: none;
    border-radius: 2px;
    border: 1px solid var(--revell);

    width: 100%;
}

.vendorquotes-column-header-input:focus {
    border-color: var(--bobyard-blue);
    background-color: aliceblue;
}

.vendorquotes-column-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    background-color: var(--revell);

    height: 40px;

    padding: 10px;
    padding-right: 0px;

    font-size: var(--small-fs);
    font-weight: var(--small-fw);

    margin-bottom: 3px;

    border: 1px solid transparent;
}

.vendorquotes-column-header:hover {
    cursor: pointer;
    border: 1px solid var(--bobyard-blue);
    border-radius: 2px;
}

.database-navbar-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}

.vendorquotes-close-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;

    font-size: var(--small-fs);
    font-weight: var(--small-fw);
    color: var(--text-color);
    border-radius: 4px;

    padding: 5px 10px;
}

.vendorquotes-close-button:hover {
    cursor: pointer;
    background-color: var(--revell);
    border-radius: 4px;
}

.database-column-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    background-color: var(--revell);

    height: 40px;

    padding: 10px;
    padding-right: 0px;

    font-size: var(--small-fs);
    font-weight: var(--small-fw);

    margin-bottom: 3px;

    white-space: nowrap;
    overflow: hidden;
}

.database-column-header:hover {
    cursor: pointer;
}

.database-column-header-text {
    display: flex;
    align-items: center;
    flex-direction: left;
    grid-area: 2px;
}

.database-column-content {
    height: calc(100vh - 40px);
    overflow: scroll;

    padding-bottom: 50vh;

    background-color: #F8F8F8;
}

.database-column-content::-webkit-scrollbar {
    display: none;
}

.database-entry {
    height: 40px;
    display: flex;
    align-items: center;

    padding: 3px;

    background-color: #F8F8F8;
}

.database-entry-hover {
    background-color: #EEEEEE;
}

.database-entry-active {
    background-color: aliceblue;
}

.database-entry-field {
    height: 34px;

    padding-left: 5px;

    width: 100%;

    border: 1px solid transparent;
    border-radius: 2px;
    background-color: white;

    outline: none;
}

.database-entry-field:hover {
    border-color: var(--bobyard-blue);
    cursor: text !important;
}

.database-entry-field:focus {
    background-color: aliceblue;
    border: 1px solid var(--bobyard-blue);
}

.database-sidebar {
    position: sticky;
    left: 0;

    background-color: white;

    height: calc(100vh - 40px);

    border-right: 1px solid lightgray;

    z-index: 10;
}

.database-sidebar-content {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 40px);
    padding-bottom: 50vh;

    overflow: scroll;

    background-color: #F8F8F8;

    z-index: 10;
}

.database-column-resize-handle {
    background-color: lightgray;
    cursor: col-resize;

    width: 2px;
}

.database-column-resize-handle:hover {
    background-color: var(--bobyard-blue);
    width: 4px;
}

.database-tree-entry {
    display: flex;
    align-items: center;

    padding: 3px;
    padding-left: 0px;
}

.database-tree-entry-icon {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 20px !important;
    height: 40px;
}

.database-tree-entry-icon:hover {
    color: var(--bobyard-blue);
    cursor: pointer;
    background-color: aliceblue;
}

.database-tree-entry-icon-viewonly {
    width: 20px !important;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.database-tree-entry-icon-viewonly:hover {
    cursor: default;
    color: black;
    background-color: inherit;
}


.database-tree-entry-icon-disabled:hover {
    cursor: default;
    color: lightgray;
    background-color: inherit;
}

.database-drag-overlay {
    background-color: white;
    white-space: nowrap;

    width: 300px;

    padding: 3px;
    padding-left: 10px;
    height: 40px;

    display: flex;
    align-items: center;

    border: 1px solid var(--revell);
    border-radius: 5px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

.database-entry-field-calculated {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;

    border-right: none;
}

.database-entry-field-calculated:focus {
    border-right: none;
}

.database-entry-field-calculated:focus+.database-entry-calculated-icon {
    border-color: var(--bobyard-blue);
    background-color: aliceblue;
}

.database-entry-field-calculated:hover+.database-entry-calculated-icon {
    border-color: var(--bobyard-blue);
}

.database-entry-calculated-icon {
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: white;
    height: 34px;

    border: 1px solid transparent;
    border-left: none;

    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
}

.database-entry-calculated-icon-override:hover {
    cursor: pointer;
}

.database-entry-calculated-icon-hover {
    margin-top: -4px;
}

.database-columns-modal-body {
    padding: 10px;
}

.database-columns-modal-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    overflow: scroll;

    height: 400px;
}

.database-columns-modal-item {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 10fr;

    width: 100%;
    height: 40px;

    padding: 10px;
    padding-right: 0px;

    font-size: var(--small-fs);
    font-weight: var(--small-fw);

    border-bottom: 1px solid lightgray;
}

.database-columns-modal-item:hover {
    cursor: pointer;

    background-color: aliceblue;
}

.database-columns-modal-title {
    font-size: var(--medium-fs);
    font-weight: var(--large-fw);
    color: var(--text-color);
}

.database-columns-modal-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    padding-bottom: 10px;
}

.database-columns-modal-header-text {
    font-size: var(--small-fs);
    font-weight: var(--small-fw);
}

.database-columns-modal-options {
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
    gap: 10px;
}

.database-columns-modal-options-item {
    display: flex;
    align-items: center;

    padding: 5px 10px;

    font-size: var(--small-fs);
    font-weight: var(--small-fw);
    color: var(--text-colorƒ);

    border: 1px solid var(--revell);
    border-radius: 4px;
    background-color: white;
}

.database-columns-modal-options-item:hover {
    cursor: pointer;
    background-color: whitesmoke;
}

.database-columns-modal-body-text {
    font-size: var(--small-fs);
    font-weight: var(--small-fw);
    color: var(--red);

    margin-bottom: 20px;
}

.database-columns-modal-body-selected {
    padding-left: 20px;
}

.database-navbar-profile-picture-link {
    text-decoration: none;
}

.database-group-edit-modal-body {
    width: 500px;

    font-size: var(--small-fs);
    font-weight: var(--small-fw);
    color: var(--text-color);

    display: grid;
    grid-template-columns: 2fr 3fr;
    gap: 10px;

    padding: 10px 10px;
}

.database-group-edit-modal-body-columns {
    max-height: 200px;
    overflow: scroll;
}

.database-group-edit-modal-body-column {
    padding: 5px 10px;
    border-bottom: 1px solid lightgray;
}

.database-group-edit-modal-body-column:hover {
    cursor: pointer;
    background-color: aliceblue;
}

.database-group-edit-modal-body-column-selected {
    background-color: aliceblue;
    color: var(--bobyard-blue);
}

.database-group-edit-modal-header {
    font-weight: var(--large-fw);
    padding-bottom: 5px;
}

.database-group-edit-modal-desc {
    margin-bottom: 10px;

    font-size: var(--xsmall-fs);
    font-weight: var(--small-fw);
    color: var(--text-color);
}

.database-group-edit-modal-body-content {
    padding: 10px;
    padding-top: 0px;
    padding-bottom: 0px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
}

.database-group-edit-modal-buttons {
    margin-top: 10px;

    display: flex;
    flex-direction: row;
    justify-content: left;
    gap: 10px;
}

.database-group-edit-modal-content {
    display: flex;
    flex-direction: column;
}

.database-group-edit-modal-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.database-group-edit-modal-field {
    height: 38px;

    padding-left: 5px;

    width: 100%;

    border: 1px solid var(--revell);
    border-radius: 2px;
    background-color: white;

    outline: none;
}

.database-group-edit-modal-field:hover {
    border-color: var(--bobyard-blue);
    cursor: text !important;
}

.database-group-edit-modal-calculated {
    margin-top: 15px;
}

.database-navbar-profile-picture {
    width: 26px;
    height: 26px;
    border-radius: 50%;

    object-fit: cover;

    border: 2px solid var(--revell);
}

.database-navbar-profile-picture-initials {
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: var(--xsmall-fs);
    font-weight: var(--medium-fw);
    color: var(--bobyard-blue);
    text-decoration: none;
    text-align: center;

    width: 28px;
    height: 28px;
    border-radius: 50%;

    border: 2px solid var(--bobyard-blue);
}

.database-navbar-profile-picture-container {
    margin-left: 40px;
    width: 40px;
    height: 40px;

    display: flex;
    align-items: center;
    justify-content: center;
}

.database-navbar-profile-picture-container:hover {
    cursor: pointer;
    background-color: var(--revell);
}

.database-navbar-profile-picture-container:hover .database-navbar-profile-picture {
    border-color: var(--bobyard-blue);
}

.database-populate-modal-body {
    padding: 10px;
}

.database-populate-modal-title {
    font-size: var(--medium-fs);
    font-weight: var(--large-fw);
    color: var(--text-color);
}

.database-populate-modal-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.database-populate-modal-desc {
    font-size: var(--small-fs);
    font-weight: var(--small-fw);
    color: var(--text-color);

    padding-bottom: 10px;
}

.database-populate-modal-error {
    font-size: var(--small-fs);
    font-weight: var(--small-fw);
    color: var(--red);

    margin-bottom: 20px;
}

.database-populate-modal-upload-placeholder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;

    padding: 20px;

    height: calc(100% - 65px);
    width: 100%;

    border: 2px dashed lightgray;

    outline: none;
}

.database-populate-modal-upload-placeholder:hover {
    cursor: pointer;
    background-color: var(--fill-gray-blue);
    color: var(--bobyard-blue);

    border: 2px dashed var(--bobyard-blue);
}

.database-populate-modal-placeholder-icon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.database-populate-modal-placeholder-body {
    font-size: var(--medium-fs);
    font-weight: var(--medium-fw);
    color: var(--text-color);
}

.database-populate-modal-header-link {
    color: var(--bobyard-blue);
    outline: none;
    text-decoration: none;
}

.database-populate-modal-header-link:hover {
    text-decoration: underline;
}

.vendorquotes-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    width: 100%;
    padding-right: 20px;
}

.vendorquotes-title {
    font-size: var(--small-fs);
    font-weight: var(--large-fw);
    color: var(--text-color);

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.vendorquotes-create-vendor {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}

.vendorquotes-modal-dialog {}

.vendorquotes-modal-content {
    overflow: hidden;
}

.vendorquotes-body {
    height: calc(80vh - 69px);
    overflow: hidden;
}

.vendorquotes-sidebar-content {
    display: flex;
    flex-direction: column;
    height: calc(80vh - 69px);
    padding-bottom: 20vh;

    overflow: scroll;
    overflow-x: hidden;

    background-color: #F8F8F8;
}

.vendorquotes-column-content {
    height: calc(80vh - 69px);
    overflow: scroll;

    padding-bottom: 20vh;

    background-color: #F8F8F8;
}

.vendorquotes-column-content::-webkit-scrollbar {
    display: none;
}

.vendorquotes-tree-entry-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    max-width: 200px;
    min-width: 100px;
}

.vendorquotes-column {
    height: calc(80vh - 69px);
}

.databasepopup-entry {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    padding-left: 10px;
    padding-right: 10px;
}

.vendor-calculated-icon:hover {
    cursor: pointer;
    background-color: var(--alice-blue);
}

.vendorquotes-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.vendorquotes-button-disabled {
    color: silver;
}

.vendorquotes-button-disabled:hover {
    cursor: not-allowed;

    color: silver;
    background-color: white;
    border-color: var(--revell);
}

.database-context-menu-group-options {
    position: absolute;
    top: 0px;
    left: calc(100% - 2px);

    max-height: 50vh;
    overflow: scroll;

    width: 300px !important;
    background-color: white;
    border: 1px solid lightgray;
    color: var(--text-color);

    border-radius: 5px;

    display: flex;
    flex-direction: column;

    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);

    padding: 5px;
}

.database-context-menu-group {
    border: 1px solid var(--revell);
    border-radius: 4px;
}

.database-context-menu-group-header {
    display: grid;
    grid-template-columns: 1fr 4fr 1fr;
    align-items: center;
    gap: 10px;

    border-radius: 4px;
    padding: 5px 10px;
}

.database-context-menu-group-name {
    font-size: var(--small-fs);
    font-weight: var(--small-fw);
}

.database-context-menu-group-select {
    border: 1px solid var(--revell);

    border-radius: 4px;
    padding: 2px 10px;

    font-size: var(--small-fs);
    font-weight: var(--small-fw);
}

.database-context-menu-group-select:hover {
    cursor: pointer;
    background-color: whitesmoke;
    border-color: var(--text-color);
}

.database-context-menu-group-children {
    padding: 5px;
}

.database-context-menu-group-header-current {
    background-color: aliceblue;
}

.database-dnd-sortable-tree_folder_assembly-icon {
    width: 20px !important;
    height: 40px;

    display: flex;
    align-items: center;
    justify-content: center;
}

.database-empty-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;

    height: 50%;
}

.database-empty-content-title {
    font-size: var(--medium-fs);
    font-weight: var(--large-fw);
    color: var(--text-color);
}

.database-empty-content-link {
    font-size: var(--small-fs);
    font-weight: var(--small-fw);
    color: var(--bobyard-blue);
}

.database-empty-content-link:hover {
    text-decoration: underline;
}
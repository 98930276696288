.export-legend-container {
    background-color: white;

    border: 1px solid black;
    border-radius: 5px;

    z-index: -2;

    pointer-events: none;

    min-width: 500px;
    min-height: 200px;

    padding-bottom: 20px;

    background-color: aliceblue;
}

.export-legend-header {
    font-size: 1.5em;
    font-weight: bold;
    text-align: center;
    padding: 5px;
}

.export-legend-group-color {
    border-radius: 50%;
    width: 20px;
    height: 20px;
}

.export-legend-group-content {
    padding: 5px 20px;

    display: flex;
    justify-content: space-between;
    align-items: center;
}

.export-legend-group-children {
    margin-left: 10%;
}

.export-legend-group-name-color {
    display: grid;
    align-items: center;
    grid-template-columns: 20px 1fr;

    gap: 10px;
}

.export-legend-group-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
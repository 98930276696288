.edithistory-columns-button {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 5px 10px;
    border: 1px solid var(--revell);
    border-radius: 5px;
}

.edithistory-columns-button:hover {
    cursor: pointer;
    background-color: var(--revell);
}

.edithistory-columns-popup {
    display: flex;
    flex-direction: column;

    background-color: white;
    z-index: 9999;

    max-height: 50vh;
    overflow: scroll;
    padding-bottom: 20%;
    padding-right: 20px;

    width: 290px;

    /*make scroolbar invisible*/
    scrollbar-width: thin;
}

.edithistory-columns-checkbox {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 5px;
}

.edithistory-columns-checkbox:hover {
    background-color: var(--revell);

    cursor: pointer;
}

.edithistory-snapshot-list-container{
    display: flex;
    flex-direction: column;

    height: calc(100vh - 40px);

    position: absolute;
    top: 40px;
    right: 0px;

    background-color: white;

    width: 20vw;

    border-left: 1px solid #e0e0e0;
}
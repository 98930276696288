.takeoff-loading {
    overflow: hidden;
    padding-top: 4vh;

    height: 100vh;

    position: relative;
    z-index: 1;
}

.takeoff-loading-navbar {
    position: fixed;
    width: 100%;

    z-index: 10;

    display: grid;
    grid-template-columns: 10fr 25fr 30fr 20fr 15fr;
    align-items: center;

    padding: 0;
    padding-left: 1%;
    height: 4vh;
    background-color: #fff;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.takeoff-loading-sidebar {
    position: fixed;
    top: 4vh;
    right: 0px;
    left: auto;

    z-index: 1;

    width: 20%;
    height: 100%;

    background-color: white;
    box-shadow: -2px 2px 2px 0 rgba(0, 0, 0, 0.2);

    padding: 2%;
    padding-top: 1%;
}

.takeoff-loading-canvas {
    height: 96vh !important;
    max-height: 96vh !important;
    min-height: 96vh !important;
    overflow: hidden;

    background-color: rgba(115, 147, 179, 0.3);

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: larger;
}

.takeoff-loading-toolbar {
    position: fixed;
    z-index: 2;

    width: 2vw;
    height: 20vh;

    top: 10%;
    left: 0.5%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    border-radius: 5px;

    box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.3);
    background-color: white;
}
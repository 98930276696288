.estimatenavdropdown-container{
    position: relative;
    z-index: 10;

    user-select: none;
}

.estimatenavdropdown-dropdown{
    position: absolute;
    top: 4vh;
    left: 0px;
    z-index: 9999;

    width: 100px;

    flex-direction: column;

    background-color: white;

    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
}

.estimatenavdropdown-button{
    padding-left: 5px;
    padding-right: 5px;

    color: #006AFF;

    height: 40px;

    display: flex;
    align-items: center;
}

.estimatenavdropdown-button:hover{
    cursor: pointer;
    color: #006AFF;
    background-color: var(--revell);
}

.estimatenavdropdown-item-link{
    color: black;
    text-decoration: none;
}

.estimatenavdropdown-item{
    font-size: 15px;

    padding: 5px;
    padding-top: 1%;
    padding-bottom: 1%;

    border-radius: 3px;

    white-space: nowrap;

    color: black;
    text-decoration: none;
}

.estimatenavdropdown-item:hover{
    color: #006AFF;
    cursor: pointer;
    background-color: whitesmoke;
}
.login-wrapper{
    padding-left: 25%;
    padding-right: 25%;
    padding-top: 10%;
    padding-bottom: 5%;
}

.error{
    color: red;
}

.login-button-disabled{
    color: gray;
}

.login-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 40%;

    margin-left: 30%;
}

.login-title{
    font-size: 30px;
    margin-bottom: 3%;
    text-align: center;
}

.inputlogin{
    display: block;
    margin-bottom: 5%;
    outline: none;

    border: 1px solid gray;

    height: 45px;
    width: 100%;

    padding: 5%;
    font-size: 18px;

    border-radius: 5px;
}

.login-button{
    margin-left: 30%;
}

.inputlogin:focus{
    border: 1px solid #006AFE;
}

.login-info{
    margin-bottom: 10px;    
}

.login-logo-container{
    position: fixed;
    top: 5%;
    left: 2%;
}

.login-logo-image{
    height: 70px;
}

.login-footer-container{
    margin-top: 5%;

    text-align: center;
}

@media only screen and (max-width: 500px) {
    .login-wrapper{
        padding-top: 40%;
        padding-left: 15%;
        padding-right: 15%;
    }

    .login-logo-container{
        position: fixed;
        top: 2%;
        left: 2%;
    }

    .login-logo-image{
        height: 50px;
    }

    .login-container{
        width: 100%;
        margin-left: 0%;
    }

    .login-button{
        margin-left: 0%;
    }

    .login-footer-container{
        margin-left: 0%;
    }
}
.custom-icon {
    display: flex;
    justify-content: center;
    align-items: center;
}

.custom-icon:hover {
    cursor: pointer;
}

.custom-icon-img {
    width: 20px;
    height: 20px;
}
.comparepages-container {
    overflow: hidden;
    height: calc(100vh - 64px);

    position: relative;
    z-index: 1;
}

.compare-pages-body {
    position: relative;
}

.compare-pages-image-container {
    position: absolute;
}

.comparepages-logo-container {
    height: 40px;

    display: flex;
    justify-content: center;
    align-items: center;

    border-bottom: 1px solid gainsboro;
}

.comparepages-logo-container:hover {
    cursor: pointer;
    background-color: aliceblue;
}

.comparepages-logo {
    height: 30px;
    /*keep aspect ratio*/
    width: auto;
}

.compare-pages-image-red {}

.compare-pages-image-blue {}

.compagepages-navbar-container {
    position: sticky;
    top: 0;
    z-index: 10;

    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;

    width: 100%;
    height: 64px;

    padding-left: 24px;
    padding-right: 24px;
    background-color: white;
    border-bottom: 1px solid var(--revell);
}

.comparepages-pageselector-container {
    position: relative;
}

.comparepages-pageselector-popup-container {
    position: absolute;

    top: 3vh;
    left: 0;

    width: 25vw;

    z-index: 10;

    background-color: #fff;

    border-radius: 5px;
    box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.1);

    padding: 2%;
    padding-top: 1%;

    color: var(--text-color);
}

.comparepages-pageselector-popup-body {
    display: flex;
    flex-direction: column;
    gap: 5px;

    overflow-y: scroll;
    max-height: 70vh;

    padding-top: 5px;
}

.comparepages-pageselector-popup-search {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;

    padding: 1%;

    width: 100%;

    border-bottom: 1px solid gainsboro;
}

.comparepages-pageselector-popup-search-input {
    outline: none;
    border: 1px solid var(--revell);
    border-radius: 5px;
    padding-left: 5px;

    width: 100%;
}

.comparepages-pageselector-popup-search-input:hover {
    border: 1px solid #006aef;
}

.comparepages-pageselector-popup-search-input:focus {
    border: 1px solid #006aef;
    background-color: var(--alice-blue);
}

.comparepages-pageselector-popup-file-container {
    border: 1px solid lightgray;
    padding: 2%;
    border-radius: 5px;
}

.comparepages-pageselector-popup-file-title {
    font-size: var(--small-fs);
    font-weight: var(--large-fw);
}

.comparepages-pageselector-popup-file-pages {
    font-size: var(--small-fs);
    font-weight: var(--small-fw);
}

.comparepages-pageselector-popup-file-page {
    border-radius: 4px;
    padding: 5px;

    display: flex;
    flex-direction: column;
    gap: 2px;
}

.comparepages-pageselector-popup-file-page-selected {
    background-color: aliceblue;
    color: #006aef;
}

.comparepages-pageselector-popup-file-page:hover {
    background-color: var(--revell);
    cursor: pointer;
}

.comparepages-pageselector-popup-file-page-disabled {
    background-color: var(--revell);
    color: gray;
}

.comparepages-empty-message-container {
    background-color: rgb(115, 147, 179, 0.3);

    height: 100vh;
    padding-left: 20%;
    padding-right: 20%;
    padding-top: 5%;
}

.comparepages-empty-message-body-step {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    gap: 10px;
}

.comparepages-empty-message-body {
    display: flex;
    flex-direction: column;
    gap: 10px;

    margin-top: 2%;
}

.comparepages-empty-message-title {
    font-size: 1.5em;
    font-weight: 600;
}

.comparepages-empty-message-body-step-number {
    /*solid circle number*/
    border-radius: 50%;
    width: 30px;
    height: 30px;
    background-color: #006aef;
    color: white;

    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.comparepagesnavbar-loading {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.compagepages-navbar-name-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
}

.compagepages-project-name {
    height: 4vh;

    color: black;

    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    gap: 5px;

    text-decoration: none !important;

    padding-left: 5px;
    padding-right: 5px;
    border-bottom: 1px solid gainsboro;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    max-width: 20vw;
}

.comparepages-pageselector-button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    height: 44px;
    padding: 10px 16px;
    border-radius: 4px;

    max-width: 300px;

    text-align: left;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;

    border: 1px solid var(--revell);
}

.comparepages-pageselector-button:hover {
    cursor: pointer;
    background-color: var(--revell);
}


.comparepages-navbar-compare-button {
    display: flex;
    height: 44px;
    padding: 10px 16px;
    justify-content: center;
    align-items: center;
    gap: 4px;

    border-radius: 4px;
    background: var(--Blue-600, #006AFF);
    color: var(--Base-White, #FFF);

    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}

.comparepages-navbar-compare-button:hover {
    cursor: pointer;
    background: var(--Blue-700, #0045ad);
}

.comparepages-navbar-compare-button-disabled {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    padding: 10px 16px;
    border-radius: 4px;

    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;

    background: var(--Gray-200, #E9E9EC);
    color: var(--Gray-400, #B3B3B3);
}

.comparepages-navbar-compare-button-disabled:hover {
    cursor: not-allowed;
}

.comparepages-navbar-compare-button-loading {
    display: flex;
    height: 44px;
    padding: 10px 16px;
    justify-content: center;
    align-items: center;
    gap: 4px;

    border-radius: 4px;
    background: var(--Blue-600, #006AFF);
    color: var(--Base-White, #FFF);

    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}

.comparepages-navbar-step {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.comparepages-navbar-comparing-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}

.comparepages-navbar-comparing-download-button{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    height: 44px;
    padding: 10px 16px;
    border-radius: 4px;

    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;

    border: 1px solid var(--revell);
}

.comparepages-navbar-comparing-download-button:hover {
    cursor: pointer;
    background-color: var(--revell);
}
.selectedtotals-container {
    position: fixed;
    z-index: 2;

    display: flex;
    gap: 5px;

    top: 45px;

    max-width: 20%;

    display: flex;
    flex-direction: column;

    background-color: white;
    padding: 5px;

    border-radius: 3px;

    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);

    user-select: none;

    max-height: 75vh;
    overflow-y: auto;
}

.selectedtotals-container-closed {
    position: fixed;
    z-index: 2;

    display: flex;
    gap: 5px;

    top: 45px;

    display: flex;
    flex-direction: column;

    background-color: white;
    padding: 5px;

    border-radius: 3px;

    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
}

.selectedtotals-close {
    padding: 5px;
    border-radius: 5px;
}

.selectedtotals-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    gap: 5px;
}

.selectedtotals-close:hover {
    cursor: pointer;
    background-color: var(--revell);
}

.selectedtotals-container-closed:hover {
    cursor: pointer;
    background-color: var(--revell);
}

.selectedtotals-title {
    font-size: var(--small-fs);
    font-weight: var(--large-fw);
    color: var(--text-color);
}

.selectedtotals-content {
    display: flex;
    flex-direction: column;

    font-size: var(--small-fs);
    font-weight: var(--small-fw);
    color: var(--text-color);

    max-width: 20vw;
}

.selectedtotals-content-row-value {
    text-align: right;
    padding-right: 4px;
}

.selectedtotals-content-row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    gap: 10px;

    border-radius: 3px;

    max-width: 20vw;
    min-width: 0px;

    height: 30px;
}

.selectedtotals-content-row-left {
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 4px;
}

.selectedtotals-content-row-title {
    text-align: left;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.selectedtotals-content-children {
    padding-left: 10px;
}

.selectedtotals-content-row:hover {
    background-color: var(--revell);
    cursor: pointer;
}

.selectedtotals-count-breakdown-row {
    display: grid;
    grid-template-columns: 1fr 8fr 2fr;
    gap: 5px;
    align-items: center;

    position: relative;
}

.selectedtotals-count-breakdown-row:hover {
    background-color: var(--revell);
    cursor: pointer;
}

.selectedtotals-count-breakdown-row-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    font-size: var(--small-fs);
    font-weight: var(--small-fw);
    color: var(--text-color);
}

.selectedtotals-count-breakdown-row-value {
    font-size: var(--small-fs);
    font-weight: var(--small-fw);
    color: var(--text-color);
}

.selectedtotals-count-breakdown-title {
    font-size: var(--small-fs);
    color: var(--text-color);
}

.selectedtotals-updating {
    display: flex;
    justify-content: center;
    padding-top: 5px;
    padding-bottom: 5px;
}

.selectedtotals-export-button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;

    border: 1px solid var(--revell);
    border-radius: 5px;
    padding: 2px 10px;

    font-size: small;
}

.selectedtotals-export-button:hover {
    cursor: pointer;
    background-color: var(--revell);
}
.projectdetails-container {
    padding-bottom: 10%;

    height: calc(100vh - 64px);

    background-color: #F9F9FB;

    display: grid;
    grid-template-columns: 1fr 3fr;

    overflow: hidden;

    user-select: none;

    color: var(--text-color);
}

.projectdetail-toastcontainer {
    margin-top: 6vh;
}

.projectdetails-sidebar {
    background-color: white;

    display: flex;
    flex-direction: column;
    gap: 15px;

    padding-top: 6%;
    padding-bottom: 20%;

    overflow: scroll;

    height: 100vh;
}

.projectdetails-sidebar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding-bottom: 2%;
    padding-left: 10%;
    padding-right: 10%;

    z-index: 999 !important;
}

.projectdetails-sidebar-header-title {
    font-size: var(--large-fs);
    font-weight: var(--large-fw);
    color: var(--text-color);
}

.projectdetails-content {
    width: 100%;
}

.projectdetails-pages-container {
    width: 100%;
    height: calc(100vh - 64px);
    overflow: scroll;

    padding: 2%;

    display: grid;
    /*grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));*/
    align-items: center;
    justify-content: center;
    gap: 10px;

    padding-bottom: 20%;
}

.projectdetails-pages-header {
    display: flex;
    justify-content: right;
    align-items: center;
    gap: 20px;

    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10%;
    padding-right: 2%;
}

.projectdetails-pages-header-button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;

    border-radius: 5px;

    background-color: white;
    color: var(--text-color);
    border: 1px solid var(--revell);

    padding: 2px;
    padding-left: 10px;
    padding-right: 10px;

    height: 30px;
}

.projectdetails-pages-header-button:hover {
    cursor: pointer;
    background-color: aliceblue;
    color: var(--bobyard-blue);
    border: 1px solid var(--bobyard-blue);
}

.projectdetails-files-container {
    padding-left: 5%;
    padding-right: 5%;

    padding-bottom: 20%;
}

.projectdetails-file-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    padding: 5%;
    padding-top: 2%;
    padding-bottom: 2%;
    gap: 10px;

    border-radius: 4px;

    font-size: var(--small-fs);
    font-weight: var(--small-fw);
    color: var(--text-color);
}

.projectdetails-file-container:hover {
    background-color: var(--revell);
    cursor: pointer;
}

.projectdetails-file-name {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.projectdetails-file-options-container {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.projectdetails-upload-input {
    display: none;
}

.projectdetails-upload-button {
    border: none;
    border-radius: 4px;
    padding-left: 5px;
    padding-right: 5px;
    padding: 5px;

    display: flex;
    align-self: center;
}

.projectdetails-upload-button:hover {
    cursor: pointer !important;
    background-color: aliceblue;
    color: #007BFF;
}

.projectdetails-upload-input {
    display: none;
}

.projectdetails-upload-input:hover {
    cursor: pointer !important;
}

.projectdetails-page-container {
    background-color: transparent;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    position: relative;

    border-radius: 5px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
    background-color: white;

    border: 1px solid transparent;
}

.projectdetails-page-container:hover {
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
    cursor: pointer;
}

.projectdetails-page-thumbnail {
    border-radius: 5px;

    width: 100%;

    border-radius: 5px;
    border: 2px solid transparent;
}

.projectdetails-page-thumbnail:hover {
    box-shadow: none;
    cursor: pointer;
}

.projectdetailsnavbar-container {
    position: sticky;
    top: 0;
    z-index: 10;

    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;

    width: 100%;
    height: 64px;

    padding-left: 24px;
    padding-right: 24px;
    background-color: white;
    border-bottom: 1px solid var(--revell);
}

.projectdetailsnavbar-logo {
    display: flex;
    justify-content: left;
    padding-left: 0px;

    min-width: 124px;
}

.projectdetailsnavbar-profile-picture {
    display: flex;
    justify-content: right;
}

.projectdetailsnavbar-project-name-container {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    display: flex;
    justify-content: left;
    align-items: center;

    text-decoration: none;
}

.projectdetailsnavbar-project-name {
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;

    border-radius: 5px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    min-width: 100px;
    max-width: 80%;
}

.projectdetailsnavbar-project-name:hover {
    background-color: aliceblue;
    cursor: pointer;
    color: #007BFF;
}

.projectdetailsnavbar-project-name-link {
    text-decoration: none;
    color: black;
}

.projectdetailsnavbar-project-name-link:hover {
    color: #007BFF;
}

.projectdetailsnavbar-options {
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
    gap: 10px;
}

.projectdetailsnavbar-option-link {
    text-decoration: none;
    color: black;
}

.projectdetailsnavbar-option {
    display: flex;
    height: 44px;
    padding: 10px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;

    white-space: nowrap;

    border-radius: 4px;
    border: 1px solid var(--revell);

    color: var(--Base-Black, #151515);

    /* Button Medium */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 150% */
}

.projectdetailsnavbar-option:hover {
    background-color: var(--revell);
    cursor: pointer;
}

.projectdetailsnavbar-option-active {
    background-color: #006aff;
    color: white;
}

.projectdetailsnavbar-option-active:hover {
    background-color: aliceblue;
    color: #006aff;
}

.projectdetails-page-status-icon {
    position: absolute;
    top: 5px;
    left: 5px;

    width: 16px;
    height: 16px;
    border-radius: 50%;

    border: 2px solid #007BFF;
}

.projectdetails-page-measurements {
    position: absolute;
    top: 3px;
    right: 28px;
    color: var(--bobyard-blue);

    background-color: var(--revell);

    border-radius: 50%;

    height: 20px;
    width: 20px;

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: var(--small-fs);
    font-weight: var(--small-fw);

    z-index: 10;
}

.projectdetails-page-aiprepped {
    position: absolute;
    top: 3px;
    right: 48px;
    color: var(--bobyard-blue);
}

.projectdetails-page-name {
    /*position: absolute;
    bottom: 0px;
    left: 5px;*/
    color: var(--text-color);

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-bottom: 2px;

    text-align: center;

    padding-left: 2px;
}

.projectdetails-page-view {
    position: absolute;
    top: 1px;
    right: 3px;
}

.projectdetails-page-view:hover {
    cursor: pointer;
    color: #007BFF;
}

.projectdetails-pagepreview-title {
    display: grid;
    grid-template-columns: 8fr 2fr 2fr 1fr 2fr;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 100%;

    user-select: none;
}

.projectdetails-pagepreview-title-pagenumber {
    text-align: left;
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 20px;

    align-self: center;
}

.projectdetails-pagepreview-title-filename {
    text-align: right;
    font-weight: 600;
    line-height: 20px;

    align-self: center;
}

.projectdetails-pagepreview-navigation {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
}

.projectdetails-pagepreview-navigation-button {
    display: flex;
    justify-content: center;

    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 5px;
    border: none;
}

.projectdetails-pagepreview-navigation-button:hover {
    cursor: pointer;
    background-color: aliceblue;
    color: #007BFF;
}

.projectdetails-pagepreview-image-container {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 82vh;

    overflow: hidden;
}

.projectdetails-pagepreview-image {
    max-height: 82vh;
}

.projectdetails-pagepreview-loading {
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.projectdetails-infopopup-container {
    position: absolute;
    bottom: 2%;
    left: 40%;

    width: 50%;

    z-index: 100;

    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;

    background-color: white;
    border-radius: 5px;

    box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.75);

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    user-select: none;
}

.projectdetails-infopopup-zoom {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
}

.projectdetails-infopopup-selectall {
    /*button*/
    padding: 2px;
    padding-left: 10px;
    padding-right: 10px;

    border-radius: 5px;

    display: flex;
    justify-content: right;
    align-items: center;
    gap: 6px;

    background-color: white;
    color: var(--text-color);
    border: 1px solid var(--revell);

    font-size: var(--small-fs);
    font-weight: var(--small-fw);
    color: var(--text-color);
}

.projectdetails-infopopup-selectall:hover {
    cursor: pointer;
    background-color: aliceblue;
    color: var(--bobyard-blue);
    border: 1px solid var(--bobyard-blue);
}

.projectdetails-infopopup-status-icon {
    width: 16px;
    height: 16px;
    border-radius: 50%;

    border: 2px solid #007BFF;
}

.projectdetails-upload-placeholder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 35px;

    height: calc(100% - 65px);
    width: 100%;

    border: 2px dashed lightgray;
    outline: none;
}

.projectdetails-upload-placeholder:hover {
    cursor: pointer;
    background-color: var(--fill-gray-blue);
    color: var(--bobyard-blue);

    border: 2px dashed var(--bobyard-blue);
}

.projectdetails-upload-placeholder-title {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.projectdetails-upload-placeholder-body {
    font-size: var(--large-fs);
    font-weight: var(--large-fw);
}

.projectdetails-file-delete:hover {
    color: red;
    cursor: pointer;
}

.projectdetails-file-download:hover {
    color: #007BFF;
    cursor: pointer;
}

.projectdetails-files-loading {
    background-color: white;

    display: flex;
    flex-direction: column;

    height: 100vh;

    padding: 10%;
    padding-top: 5%;
    padding-bottom: 20%;
}

.projectdetails-content-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.projectdetails-pagepreview-title-toggle {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5px;

    margin-top: 0px;

    line-height: 20px;

    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 5px;

    height: 34px;
}

.projectdetails-pagepreview-title-toggle:hover {
    padding-top: 5px;
    padding-bottom: 5px;
    cursor: pointer;
    background-color: aliceblue;
}

.projectdetails-fileuploader {
    width: 500px !important;

    color: red;
}

.projectdetails-details-modal-body {
    padding: 2%;

    display: flex;
    flex-direction: column;
    gap: 20px;

    height: 50vh;
}

.projectdetails-details-modal-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.projectdetails-details-modal-close {
    padding: 5px;
    border-radius: 5px;
}

.projectdetails-details-modal-close:hover {
    cursor: pointer;
    background-color: var(--revell);
}

.projectdetails-details-modal-title {
    font-size: var(--large-fs);
    font-weight: var(--large-fw);
    color: var(--text-color);
}

.projectdetails-details-modal-body-row {
    display: grid;
    grid-template-columns: 1fr 3fr;
    align-items: center;
    gap: 10px;
}

.projectdetails-details-modal-body-row-title {
    font-size: var(--medium-fs);
    font-weight: var(--large-fw);
    color: var(--text-color);
}

.projectdetails-details-modal-body-row-value {
    font-weight: 400;
}

.projectdetails-autocomplete-container {
    position: relative;
}

.projectdetails-autocomplete-dropdown-container {
    position: absolute;
    top: 110%;
    left: 26%;

    width: 60%;
    max-height: 200px;

    overflow: scroll;

    background-color: white;
    border-radius: 5px;

    z-index: 100;

    border: 1px solid lightgray;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
    padding: 1%;
}

.projectdetails-suggestion-item {
    padding-left: 5px;
    padding-right: 5px;

    border-radius: 3px;
}

.projectdetails-suggestion-item:hover {
    background-color: aliceblue;
    cursor: pointer;
}

.projectdetails-suggestion-item-active {
    background-color: aliceblue;
    color: #007BFF;

    padding-left: 5px;
    padding-right: 5px;

    border-radius: 3px;
}

.projectdetails-suggestion-item-active:hover {
    background-color: aliceblue;
    color: var(--bobyard-blue);
    cursor: pointer;
}

.projectdetails-details-modal-desc {
    display: grid;
    grid-template-columns: 1fr 3fr;
    align-items: top;
    gap: 10px;
}

.projectdetails-details-modal-desc-input {
    width: 100%;
    border-radius: 3px;
    border: 1px solid var(--revell);
    padding: 5px;

    max-height: 35vh;

    outline: none;

    font-size: var(--small-fs);
    font-weight: var(--small-fw);
    color: var(--text-color);
}

.projectdetails-details-modal-desc-input:hover {
    border: 1px solid var(--bobyard-blue);
}

.projectdetails-details-modal-desc-input:focus {
    border: 1px solid var(--bobyard-blue);
    background-color: aliceblue;
}

.projectdetails-projectlist-button {
    height: 40px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;

    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.projectdetails-projectlist-button:hover {
    cursor: pointer;
    background-color: aliceblue;
    color: #007BFF;
}

.estimate-projectlist-button {
    height: 40px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.estimate-projectlist-button:hover {
    cursor: pointer;
    background-color: var(--revell);
    color: #007BFF;
}